import React, {useEffect, useRef} from 'react';
import XImageSequence from "./X-Scroll/XImageSequence";
import './Artists_Brand.css';
import { Controller, Scene } from "react-scrollmagic";
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Artists_Brand = () => {
  const ref = useRef();

  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }

  useEffect(() => {
    window.onscroll = function() {
      fixedPosition()
    };
    
    var elem = document.getElementById("collab");
    var divHeight = document.getElementById("collab-container").clientHeight;
    var height = window.pageYOffset + elem.getBoundingClientRect().top + (divHeight/5);

    function fixedPosition() {
      if (window.pageYOffset <= height || window.pageYOffset >= height+divHeight+(divHeight/4)) {
        elem.classList.remove("sticky");
        elem.classList.remove("video-display");
      } else{
        elem.classList.add("sticky");
      }
    }
  }, []);

  return (
    <>
     <div 
      id='collab-container'
      className="p-0"
      style={{
        minWidth: "100%", 
        marginBottom: "20vh",
        marginTop: mobile? "10vh" : ""
      }}
    >
      <div className='collab'>
        <Controller>
          <Scene duration="100%" triggerHook="onLeave" pin={{spacerClass:"sequence"}}>
            {progress => (
              <div style={{ height: "100vh", position: "relative"}} className="sequence">
                <XImageSequence ref={ref} progress={progress} />
                {mobile && (
                  <video 
                    id="collab" 
                    className='xrender'
                    autoPlay
                    loop
                    playsInline
                    muted
                  >
                    <source src={require("../../../assets/videos/Artists_Brand/Artists_Brand_Mobile.mp4")} type="video/mp4"/>
                    <source src={require("../../../assets/videos/Artists_Brand/Artists_Brand_Mobile.webm")} type="video/webm"/>
                  </video>
                )}
                {!mobile && (
                  <video 
                    id="collab" 
                    className='xrender'
                    autoPlay
                    playsInline
                    loop
                    muted
                  >
                    <source src={require("../../../assets/videos/Artists_Brand/Artists_Brand.webm")} type="video/webm"/>
                    <source src={require("../../../assets/videos/Artists_Brand/Artists_Brand.mp4")} type="video/mp4"/>
                  </video>
                )}
              </div>
            )}
          </Scene>
        </Controller>
      </div>
      </div>
    </>
  );
};
export default Artists_Brand;