const data = [
  {
   "id": 0,
   "image": require("../../../assets/images/Spotlight/spotlight1.webp"),
   "blogImage1": require("../../../assets/images/blogs/blog1/blog1.png"), 
   "title-landing": "Balmain x Barbie",
   "title": "BALMAIN PARTNERS WITH BARBIE FOR A SPECIAL, LIMITED-EDITION COLLECTION",
   "para1": "In today’s Xilver X Highlights, we see a revolutionary partnership in the foreground as Barbie and Balmain come together in this unique collaboration to create a brand new chapter in the toy and fashion industry. <br/><br/> It’s great to see an iconic brand like Barbie, one that has dominated the toy industry for several decades and created an immense sense of ownership amongst young girls and teenagers, come together with luxury fashion house Balmain to launch a limited edition “Balmain X Barbie” collection.",
   "para2": "“For this collaboration, we are building upon the new New French Style of Balmain’s most recent collections, once again showing that Balmain is inspired by the truly diverse and exciting beauty found on today’s Parisian boulevards and avenues. This collection also rejects any arbitrary gender limitations— this is an almost 100% unisex collection.” said Balmain’s Creative Director, Olivier Rousteing. <br/><br/>This collaboration might be the turning point as we start to see more luxury fashion houses come together with giant toy manufacturers that have a nostalgic brand value with many millennials, and their target demographic.<br/><br/><br/>What major toy manufacturer should Balmain work with next? Sign up for Xilver Labs Newsletter and stay up to date on all the latest collaborations.",
  },
  {
    "id": 1,
    "image": require("../../../assets/images/Spotlight/spotlight2.webp"),
    "blogImage1": require("../../../assets/images/blogs/blog2/blog2.png"), 
    "title": "MONCLER MAYA 70 COLLABORATIONS",
    "title-landing": "Moncler x Palm Angels",
    "para1": "In honor of their 70th anniversary, Moncler have invited seven designers to reinterpret their most iconic designs - Representing 70 years of fearless experimentation, the Moncler Maya jacket has been reimagined by seven different designers, all friends and collaborators of the brand. The first to drop is created by Palm Angels® and illustrates the designer's take on Moncler's future.<br/><br/>Palm Angels stems from an Italian take on American Culture and Subculture - Palm Angels is essentially a vision. Milan native FRANCESCO RAGAZZI started PALM ANGELS in 2011 as a photographic representation of the skater culture in LA and areas around California. The same project soon evolved into a book published by RIZZOLI IN 2014, and subsequently into a clothing line in 2015.",
    "para2": "“In celebration of our first 70 years marked by collaboration and innovation, we have invited Palm Angels®' Francesco Ragazzi to reimagine our Moncler Maya 70 down jacket. A luminous take on the classic puffer, the jacket is literally a source of light when you turn it on. By crafting the jacket with optical fibers and powering it with batteries, the designer wanted to project Moncler in the future.” as described on Moncler’s site.<br/><br/>The Moncler Maya has evolved over the decades with various iterations and evolutions to become one of Moncler’s most cherished styles, and now with 70 new collaborations of the product said to release, it’s exciting to see this next wave of the Maya re-imagined. These are the exact type of concepts we focus upon at Xilver Labs, where our in house artists strive to fuse fashion, luxury, and heritage all in one.<br/><br/><br/>Xilver Labs is dedicated to showcasing such collaborations on a grand scale, so stay tuned to Xilver for updates on all the latest collaborations.",
  },
  {
    "id": 2,
    "image": require("../../../assets/images/Spotlight/spotlight3.jpeg"),
    "blogImage2": require("../../../assets/images/blogs/blog3/blog3_1.png"),
    "blogImage3": require("../../../assets/images/blogs/blog3/blog3_2.png"),
    "blogImage4": require("../../../assets/images/blogs/blog3/blog3_3.png"),
    "blogImage5": require("../../../assets/images/blogs/blog3/blog3_4.png"),
    "blogImage6": require("../../../assets/images/blogs/blog3/blog3_5.png"),
    "title": "Off-White X Nike",
    "title-landing": "Off-White x Nike",
    "para1": "Off-white was started back in 2012 as ‘PYREX VISION’ in Italian city Milan by Virgil Abloh. Afterwards Abloh rebranded the name as off-white as the grey area between white and black and termed it off-white. <br/><br/>Since then, off-white has clothing, footwear, bags, accessories and jewelry. It has presented collections at Paris Fashion Week and has its retail stores in Hong Kong, Tokyo, Milan, London, the United Kingdom, and New York. Late Virgil Abloh launched his first sneaker collection with Nike back in 2017 and it changed the sneaker world completely.<br/><br/>Xilver Labs brings you the top five Off- White x Nike sneaker collaborations from the past decade:<br/><br/><br/><br/><h3>1. Off-White x Nike Air Max 97</h3><br/>",
    "para2": "The Air Max 97 is a bit of a surprise on the list, but it is undoubtedly one of the best Air Max silhouettes of all time. It is a shoe that fans have always adored, and when it was announced that it would be featured in 'The Ten', people were overjoyed. While the original colorway has its advantages, the all-black edition is just superior. With its all-black upper, silver Nike swoosh, and Off-White aesthetics scattered throughout, this is one of those sneakers that is a must-have even if the price tag is prohibitively expensive. We can enjoy the ordinary old Air Max 97s that are released on a weekly basis, but these are a different kind.<br/><br/><br/><br/><h3>2. Off-White x Air Jordan 1 High “Chicago”</h3><br/><br/>",
    "para3": "The Air Jordan 1 High “Chicago” is by far the best shoe Vergil has ever made for Nike. When The Ten came out, this was the shoe everyone wanted. The Air Jordan 1 could be good enough on its own, but when you pair the shoe's most iconic colorway with Virgil's aesthetics, you've got a commendable and memorable sneaker among the best shoes ever on the market. From the 'Air' on the midsole to the lettering on the side to the tab on the collar, this was a sneaker you had to own. Unfortunately, scalpers need a lot of fabric to add these to your collection.<br/><br/><br/><br/><h3>3. Off-White x Air Jordan 5 “Black”</h3><br/><br/>",
    "para4": "Jordan Brand launched a slew of new colorways and retros to commemorate the 30th anniversary of the Air Jordan 5. One of the models released was an Off-White x Air Jordan 5 collaboration, just in time for All-Star weekend. The transparent black coloring and yellow-tinted midsole made this sneaker stand out. Not to mention, the sneaker had small windows on the side and even the back heel, which gave it some character. It wasn't the flashiest shoe Virgil had released, but it was exceedingly clean and received tremendously great feedback from fans. In fact, we obtained a second hue named 'Sail' since this shoe was so fantastic. <br/><br/><br/><br/><h3>4. Off-White x Air Jordan 2 Low “Red”<h3><br/><br/>",
    "para5": "Abloh's last collaboration with Nike was the Air Jordan 2 Low. The Jordan 2, and of course the Jordan 2 Low, are silhouettes that are often forgotten by sneaker enthusiasts. We all know the Jordan 2 was the shoe that forced Michael Jordan to leave Nike. Regardless, Abloh decided to take the risk and it finally paid off when he created two very cool colorways of his. For this list, he chose the white and red colorway as the better of the two because it has a classic vintage look that is also an homage to Michael Jordan's Chicago Bulls days. Virgil made the Air Jordan 2 Low interesting with his DIY aesthetic, bringing a generation of sneakerheads to the understated model. Nevertheless, you should include shoes in this list. <br/><br/><br/><br/><h3>5. Off-White x Nike Dunk Low (50-Piece Collection)<h3><br/><br/>",
    "para6": "Given the 50 distinct colorways of this sneaker, this is one of the more difficult models to rank here. For those who don't recall, this model debuted in 2021, with 49 of the 50 variants sporting the identical white and grey colour scheme. The main distinction was that each pair came with a different colour of laces. The collection featured a sense of randomness to it, which delighted fans because they had no idea what kind of pair they would receive upon delivery. It was a very unusual concept that appealed to Virgil's ingenuity as someone who enjoys customising. While the shoe's colorway was unremarkable, the story behind it all was excellent, and at the end of the day, you can't go wrong with a Nike Dunk.<br/><br/><br/><br/>Off- White was one of the earlier companies to pioneer the concept of the best of both world collaborations - Read more about these type of collaborations at Xilver.io",
   },
   {
    "id": 3,
    "image": require("../../../assets/images/blogs/blog4/blog4_1.jpg"),
    "blogImage1": "", 
    "blogImage2": require("../../../assets/images/blogs/blog4/blog4_1.jpg"),
    "blogImage3": require("../../../assets/images/blogs/blog4/blog4_2.jpg"),
    "title": "Sabyasachi x Starbucks",
    "title-landing": "Sabyasachi x Starbucks",
    "para1": "Sabyasachi Mukherjee is one of the India’s most prolific fashion designers, jewelry designer and retailer. He sells his designs under his label Sabyasachi. He is a member of fashion design council of India. He has even designed costumes for many Bollywood (Hindi film cinema) movies.<br/><br/><br/> Starbucks is world’s biggest coffeehouse started in Seattle and has presence in more than 34,000 locations in over 80 countries all over the world. The company had it’s IPO in June of 1992 which enabled company to raise capital and they doubled their stores in just 2 years. Just 3 months after the IPO the share price rose to 70%. Starbucks has grown rapidly in these 3 decades since their IPO. Starbucks stores have different variants accustomed to the taste of people. Starbucks has visioned to increase its store count to 55,000 till 2030. Starbucks had a major change in their management with Indian origin Laxman Narasimhan being its CEO and collaboration with arguably the biggest Indian designer.<br/><br/><br/>",
    "para2": "Xilver brings to you the top collections of drinkware covered in this collab. <br/><br/><br/>Launching exclusively in India on April 12th, Starbucks India x Sabyasachi is a drinkware line comprised of ceramic and steel mugs. They all have Toile de Jouy signature flora and fauna art prints from his art foundation. The prints bear some resemblance to sketches for British luxury brand Thomas Goode and certain works from a collaboration with US brand Pottery Barn. The line features eagles, crocodiles, and his logo, the Bengal tiger, in muted tones of green, red, and charcoal. Sold individually, they make a fun and mismatched coffee table set. <br/><br/><br/>At a press conference Mukherjee said, “Collaborations make sense to either make money or impact.” This collab aims at both. The part of the money will go to a NGO, as support towards the education of 3,000 girls. To acknowledge this, the coffee chain for the first time will change its popular call-out for costumers as it will not be the buyer’s name, but a thank you from the girls that’ll be helped with the sale of this merchandise.<br/><br/><br/>",
    "para3": "In a fledgling fashion industry like India, where collaboration is gaining ground, partnerships can increase brand awareness and pave the way for growth. A collaboration between a popular designer and a loved brand can act as an entry level selling for 20$ to 30$ only. <br/><br/><br/>Stay tuned to Xilver for updates on all the most recent collaborations because Xilver Labs is committed to exhibiting latest collaborations on a large scale.  ",
  },
  {
    "id": 4,
    "image": require("../../../assets/images/blogs/blog5/blog5_1.jpg"),
    "blogImage2": require("../../../assets/images/blogs/blog5/blog5_1.jpg"),
    "blogImage3": require("../../../assets/images/blogs/blog5/blog5_2.png"),
    "blogImage4": require("../../../assets/images/blogs/blog5/blog5_3.png"),
    "blogImage5": require("../../../assets/images/blogs/blog5/blog5_4.png"),
    "blogImage6": require("../../../assets/images/blogs/blog5/blog5_5.png"),
    "blogImage7": require("../../../assets/images/blogs/blog5/blog5_6.png"),
    "blogImage8": require("../../../assets/images/blogs/blog5/blog5_7.png"),
    "title": "Superhero Slick: Sketchers x Marvel",
    "title-landing": "Superhero Slick: Sketchers x Marvel",
    "para1": "THE MARVEL UNIVERSE has been successful lately, with a bunch of new TV shows and games in the works and the upcoming Black Panther and Wakanda Forever release. If you are looking for a fun way to support your favorite franchise or are just a fan of collecting Marvel merchandise, you will love this newly launched Skechers x Marvel collection designed for necessary workers.<br/><br/><br/>",
    "para2": "<br/>If you have a job that requires you to walk all day, a comfortable, safety-focused shoe is a must. Thanks Marvel and Sketchers, these shoes can be fun too. The two companies have teamed up to create a superhero-themed Sketcher work shoe collection that includes sneakers and boots with features like air-cooled memory foam, an ESD design that dissipates Complete electrostatic safety in electrically sensitive workplaces, steel toes, slip resistance and waterproofing. These work-oriented features are combined with a style that features Marvel characters such as Spider-Man, Punisher, Captain Marvel and Iron Man. You can purchase the entire Marvel x Sketchers Work collection here on the Sketchers website for prices ranging from $85 to $125.  <br/><br/><br/>Xilver showcases the most exiting shoes from this collaboration. <br/><br/><br/><br/><b>Marvel: Squad SR – Webbey (98 USD)</b><br/><br/>",
    "para3": "The anti-slip design features a Spider-Man-themed printed synthetic mesh and upper with a decorative stretch lace front and air-cooled memory foam midsole. Even without Spidey's senses, you can be assured of safety and comfort wearing Marvel x Skechers Work: Squad SR - Webbey. <br/><br/><br/><br/><b>Marvel: Gatlon – Spidey ST (125 USD)</b><br/><br/>",
    "para4": "This Spider-Man-themed hiking design features a Spider-Man-themed mesh, leather and synthetic upper with puncture-resistant soles and steel toecaps. Take your Spidey senses to the great outdoors with Marvel x Skechers Work: Gatlon - Spidey ST. <br/><br/><br/><br/><b>Marvel: Uno SR – Assemble (105 USD)</b><br/></br/>",
    "para5": "The perforated synthetic durabuck upper with the famous Avenger's insignia and a 'Find Your Power' graphic, Skechers Air-Cooled Memory Foam insole, and Skech-Air visible air-cushioned midsole make up this Relaxed Fit design. With the Marvel x Skechers Work: Uno SR - Assemble, you can join forces with your squad in guaranteed safety and comfort.<br/></br/></br/><br/><b> Marvel: Squad SR – Captain Marvel (85 USD)</b><br/><br/>",
    "para6": "This Slip-Resistant Relaxed Fit work shoe has a mesh and synthetic upper with an allover Captain Marvel themed graphic, a deco stretch-lace front, and a Skechers Memory Foam cushioned comfort insole. Wearing the Marvel x Skechers Work: Squad SR - Captain Marvel, you can lead your squad with the confidence of a Super Hero. <br/><br/><br/><br/> <b>Marvel: Wascana – The Punisher (105 USD)</b><br/><br/>",
    "para7": "This lace-up leather and synthetic tactical style boot has a print of The Punisher's trademark emblem on contrast mesh panels and a Skechers Air-Cooled Memory Foam insole. Channel your inner Super Hero while wearing the Marvel x Skechers Work: Wascana - The Punisher. <br/><br/><br/><br/><b>Marvel: Trevok – Armor Out (125 USD) </b><br/><br/>",
    "para8": "Skechers Air-Cooled Memory Foam insole, steel toe, and lace-up leather and synthetic upper with Iron Man's famous emblem at the side heel. Wear the Marvel x Skechers Work: Trevok - Armor Out to channel your inner Iron Man and equip yourself with long-lasting comfort. <br/><br/><br/>Would you want to try one of these superhero shoes? Sign up with Xilver Labs blogs to know about the most trendy and latest collaborations.",
  },
]

export default data;