import React, { forwardRef } from "react";
import { Skeleton } from "./Skeleton";
import XImageArray from "./XImageArray";
import './XSequence.css'

const XImageSequence = forwardRef(({ progress }, ref) => {
  const newImages = XImageArray();

  let index = Math.round(progress * 1 * (newImages.length - 1));

  if (newImages[index][1] !== undefined) {
    if (newImages[index][1] === "loading") {
      return <Skeleton width="100%" height="100%" />;
    } else {
      return newImages.map((item, i) => (
        <span
          ref={ref}
          key={i}
          style={{
            display: i !== index ? "none" : "inline-block",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url('${item[0] ? item[0].src : null}')`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
      ));
    }
  }
});

export default XImageSequence;
