import React, { useRef } from "react";
import { Controller, Scene } from "react-scrollmagic";
import ImageSequence from "./ImageSequence";
import "./Sequence.css";
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ImageSequenceMobile from "./ImageSequenceMobile";
import Contact from "../Contact/Contact";
import Partner from "../Partner/Partner";

const Sequence = () => {
  const ref = useRef();
  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }

  return (
    <>
      <Controller>
        <Scene duration="100%" triggerHook="onLeave" pin={{spacerClass:"sequence"}}>
          {progress => (
            <div style={{ height: "100vh", position: "relative"}} className="sequence">
              {mobile && <ImageSequenceMobile ref={ref} progress={progress} />}
              {!mobile && <ImageSequence ref={ref} progress={progress} />}
            </div>
          )}
        </Scene>
      </Controller>
    </>
  );
};

export default Sequence;