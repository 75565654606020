import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const copyRightStyle = {
  fontSize: '1.4vmax',
  color: '#FFFFFF'
};

const copyRightStyleMobile = {
  fontSize: '2vmax',
  color: '#FFFFFF'
};

export default function Footer() {
  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }
  const fluid = mobile ? false : true;
  const history = useHistory();

  return (
    <>
      {!mobile && (
        <div style={{ width:"100%", position:"absolute", backgroundColor: 'black', padding:"40px" }} className="font-face-nexa">
          <footer className="footer">
            <Container 
              fluid={fluid}
              style={{
                marginTop: "5vh",
                marginBottom: "5vh"
              }}
            >
              <Row
                className="row-grid justify-content-between text-left"
                style={{ marginLeft: !mobile ? '5vw' : '', marginRight: !mobile ? '5vw' : '' }}
              >
                <Col xs="12" md="12">
                  <div
                    className="title"
                    style={{
                      fontSize: '2.5vmax',
                      marginBottom: '1vw',
                      fontWeight: '900',
                      color: '#C0C0C0',
                      cursor: "pointer"
                    }}
                    onClick={() => history.push("/")}
                  >
                    XILVER
                  </div>
                  <div style={copyRightStyle} className="mb-6">
                    &#169; 2022 Xilver Labs, Boston, MA
                  </div>
                  <div style={copyRightStyle} className="mb-6">
                    A Mystry Inc. company
                  </div>
                  <div style={copyRightStyle} className="mb-6">
                    All Rights Reserved.
                  </div>
                </Col>
              </Row>
            </Container>
          </footer>
        </div>
      )}
      {mobile && (
        <div style={{ backgroundColor: 'black', padding:"40px" }} className="font-face-nexa">
          <footer className="footer">
            <Container 
              fluid={fluid}
              style={{
                marginTop: "5vh",
                marginBottom: "5vh"
              }}
            >
              <Col
                className="row-grid justify-content-between text-left"
                style={{ marginLeft: !mobile ? '5vw' : '', marginRight: !mobile ? '5vw' : '' }}
              >
                <Col xs="12" md="12">
                  <div
                    className="title"
                    style={{
                      fontSize: '4vmax',
                      fontWeight: '900',
                      marginBottom: '2.5vh',
                      color: '#C0C0C0',
                      cursor: "pointer"
                    }}
                    onClick={() => history.push("/")}
                  >
                    XILVER
                  </div>
                  <div style={copyRightStyleMobile} className="mb-6">
                    &#169; 2022 Xilver Labs, Boston, MA
                  </div>
                  <div style={copyRightStyleMobile} className="mb-6">
                    A Mystry Inc. company
                  </div>
                  <div style={copyRightStyleMobile} className="mb-6">
                    All Rights Reserved.
                  </div>
                </Col>
              </Col>
            </Container>
          </footer>
        </div>
      )}
    </>
  );
}