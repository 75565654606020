import React from "react";
import { quizzes } from "./Quizzes";
import { shuffle } from "./Shuffle";
import Question from "./Question";
import Result from "./Result";
import "./styles.css";
import './SneakerCollab.css';
import {Container, Col, Row} from "reactstrap"

class Quiz extends React.Component {
  state = {
    currentQuiz: 0,
    numOfQuestions: 0,
    score: 0,
    resultSummary: false,
    animation: false
  };

  applyAnimation = () => {
    return !this.state.animation
      ? this.setState({ animation: "multiple-choices" })
      : this.setState({ animation: false });
  };

  handleNextQuestion = answer => {
    const { currentQuiz, numOfQuestions, score } = this.state;

    if (answer) {
      this.setState(
        {
          score: score + 1,
          numOfQuestions: numOfQuestions + 1
        },
        this.applyAnimation
      );
    } else {
      this.setState(
        {
          numOfQuestions: numOfQuestions + 1
        },
        this.applyAnimation
      );
    }

    if (numOfQuestions === quizzes[currentQuiz].questions.length - 1) {
      this.setState({
        numOfQuestions,
        resultSummary: true
      });
    }
  };

  handleNextQuiz = () => {
    const { currentQuiz } = this.state;

    if (currentQuiz + 1 === quizzes.length) {
      this.setState({
        resultSummary: false,
        numOfQuestions: 0,
        score: 0,
        currentQuiz: 0
      });
    } else {
      this.setState({
        resultSummary: false,
        numOfQuestions: 0,
        score: 0,
        currentQuiz: currentQuiz + 1
      });
    }
  };

  render() {
    const {
      currentQuiz,
      numOfQuestions,
      score,
      resultSummary,
      animation
    } = this.state;

    const { title, questions } = quizzes[currentQuiz];
    const currentQuestion = questions[numOfQuestions];

    const shuffledAnswerChoices = shuffle([
      ...currentQuestion.incorrectAnswers,
      currentQuestion.correctAnswer
    ]);

    return (
      <>
        <Container style={{marginBottom: "20vh"}}>
          <h1 
            style={{
              marginTop: "10vh",
              marginBottom: "10vh",
              color: "white"
            }}
            className='font-face-nexa'>
            Sneaker Collab Game
          </h1>
          <Row>
            <Col>
              <div className='center_image'>
                <img 
                  id="shoe_id"
                  className='img-fluid shoe_img'
                  alt="xilver_shoes"
                  height="450px"
                  width="450px"
                  src={currentQuestion.imageUrl}
                />
              </div>
            </Col>
            <Col xs={6} md={6}>
              <h3 
                className='font-face-nexa'
                id="question"
                style={{
                  color: "white"
                }}>
                Guess the Collab
              </h3><br/>
              {resultSummary ? (
                <Result
                  score={score}
                  numOfQuestions={numOfQuestions}
                  nextQuizHandler={this.handleNextQuiz}
                />
              ) : (
                <Question
                  currentQuestion={currentQuestion}
                  shuffledAnswerChoices={shuffledAnswerChoices}
                  nextQuestionHandler={this.handleNextQuestion}
                  animation={animation}
                />
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Quiz;
