import React from 'react';
import { Container } from 'reactstrap';
import "./Partner.css";
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Partner = () => {
  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }
  const fluid = true;

  return (
    <>
     <Container 
      fluid={fluid}
      className="p-0 m-0 font-face-nexa"
      style={{maxWidth: "100%"}}
    >
      <div className="partners-div d-flex justify-content-center align-items-center">
        {mobile && (
          <div>
            <video 
              autoPlay
              className='partners-video-mobile'
              loop
              muted
              playsInline
            >
              <source
                src={
                  require("../../../assets/videos/Partner/brands.hevc.mp4")
                }
                type="video/mp4; codecs=hvc1"
              ></source>
              <source src={require("../../../assets/videos/Partner/brands_portrait.mp4")} type="video/mp4"/>
            </video>
            <div className="partner-overlay">
              <span 
                className='font-face-montserrat p-0 m-0' 
                style={{
                  fontSize: "2rem",
                }}
              >
                Collaborators 
                <br/> 
                  X
                <br/> 
                Collaborations
              </span>
            </div>
          </div>
        )}
        {!mobile && (
          <div className='partners-round'>
            <video 
              autoPlay
              className='partners-video'
              loop
              muted
              playsInline
              poster={require('../../../assets/images/thumbnails/Partners.png')}
            >
              <source src={require("../../../assets/videos/Partner/brands_landscape.mp4")} type="video/mp4"/>
            </video>
            <div className="partner-overlay">
              <span className='font-face-montserrat' style={{fontSize: "4vw"}}>Collaborators X Collaborations</span>
            </div>
          </div>
        )}
      </div>
     </Container>
    </>
  );
};
export default Partner;