import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import "./Landing.css";
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useHistory } from 'react-router-dom';

const LandingSubsection = () => {
  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }
  const fluid = mobile ? false : true;
  const history = useHistory()

  // function iOS() {
  //   return [
  //     'iPad Simulator',
  //     'iPhone Simulator',
  //     'iPod Simulator',
  //     'iPad',
  //     'iPhone',
  //     'iPod'
  //   ].includes(navigator.platform)
  //   || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  // }
  // const [isIOS, setIsIOS] = useState(iOS());

  return (
    <>
      <Container 
        fluid={fluid}
      >
        <Row className='font-face-nexa'>
          <Col md="6">
            <div className={mobile? "sub-mobile" : "video-subsection"}>
              <video 
                id="video" 
                autoPlay
                loop
                style={{
                  zIndex: 1,
                }}
                poster={require('../../../assets/images/thumbnails/LandingSubsection.png')}
                playsInline
                muted
              >
                <source 
                  src={require("../../../assets/videos/Landing/LandingSubsection.mp4")} 
                  type="video/mp4"
                />
              </video>
            </div>
          </Col>
          {/* {isIOS && (
            <Col md="6" className="d-flex justify-content-center align-items-center">
              <div className={mobile? 'content-mobile': 'xilver-content'}>
                <p>Xilver Labs is a Media House dedicated to showcasing the world’s most influential concepts and collaborations across brands, artists, film, & fashion.</p>
                <a href="/about">
                  <button onClick={() => window.replace('/about')}
                    className="btn-xilver-about">
                    About Us
                  </button>
                </a>
              </div>
            </Col>
          )} */}
          {/* {!isIOS && ( */}
            <Col md="6" className="d-flex justify-content-center align-items-center">
              <div className={mobile? 'content-mobile': 'xilver-content'}>
                <p>Xilver Labs is a Media House dedicated to showcasing the world’s most influential concepts and collaborations across brands, artists, film, & fashion.</p>
                <div onClick={() => history.push("/about")}>
                  <button
                    className="btn-xilver-about">
                    About Us
                  </button>
                </div>
              </div>
            </Col>
          {/* )} */}
        </Row>
      </Container>
    </>
  );
};
export default LandingSubsection;