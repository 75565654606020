import React from "react";
import "./Slide.css";
import "./CarSection.css"
import useWindowDimensions from '../../hooks/useWindowDimensions';

export const Slide = React.memo(function (StackedCarouselSlideProps) {
  const {
    data,
    dataIndex,
    isCenterSlide,
    swipeTo,
    slideIndex
  } = StackedCarouselSlideProps;

  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }


  const coverImage = data[dataIndex].image;
  const coverVideo = data[dataIndex].video;
  const coverText = data[dataIndex].text;

  return (
    <div className="card-card d-flex justify-content-center align-items-center" draggable={false}>
      <div className={`cover fill ${isCenterSlide ? "off" : "on"}`}>
        <div
          className="card-overlay fill"
          onClick={() => {
            if (!isCenterSlide) swipeTo(slideIndex);
          }}
        />
      </div>
      <div className="detail fill">
        <div className="discription">
          <div className="style_prevu_kit">
              {!mobile && <img 
                className="static cover-image" alt="xilver_collab"
                src={coverImage}/>}
              <img 
                className="active cover-video" alt="xilver_collab"
                src={coverVideo}
              />
            </div>
            <p className='movieXcar show-p'>{coverText}</p>
          </div>
      </div>
    </div>
  );
});
