import { Car } from "./Images";
import { useImage } from "./Hooks";

const CarArray = () => {
  const [AA0, AA0S] = useImage(Car[0]);
  const [AA1, AA1S] = useImage(Car[1]);
  const [AA2, AA2S] = useImage(Car[2]);
  const [AA3, AA3S] = useImage(Car[3]);
  const [AA4, AA4S] = useImage(Car[4]);
  const [AA5, AA5S] = useImage(Car[5]);
  const [AA6, AA6S] = useImage(Car[6]);
  const [AA7, AA7S] = useImage(Car[7]);
  const [AA8, AA8S] = useImage(Car[8]);
  const [AA9, AA9S] = useImage(Car[9]);

  const [AA10, AA10S] = useImage(Car[10]);
  const [AA11, AA11S] = useImage(Car[11]);
  const [AA12, AA12S] = useImage(Car[12]);
  const [AA13, AA13S] = useImage(Car[13]);
  const [AA14, AA14S] = useImage(Car[14]);
  const [AA15, AA15S] = useImage(Car[15]);
  const [AA16, AA16S] = useImage(Car[16]);
  const [AA17, AA17S] = useImage(Car[17]);
  const [AA18, AA18S] = useImage(Car[18]);
  const [AA19, AA19S] = useImage(Car[19]);

  const [AA20, AA20S] = useImage(Car[20]);
  const [AA21, AA21S] = useImage(Car[21]);
  const [AA22, AA22S] = useImage(Car[22]);
  const [AA23, AA23S] = useImage(Car[23]);
  const [AA24, AA24S] = useImage(Car[24]);
  const [AA25, AA25S] = useImage(Car[25]);
  const [AA26, AA26S] = useImage(Car[26]);
  const [AA27, AA27S] = useImage(Car[27]);
  const [AA28, AA28S] = useImage(Car[28]);
  const [AA29, AA29S] = useImage(Car[29]);

  const [AA30, AA30S] = useImage(Car[30]);
  const [AA31, AA31S] = useImage(Car[31]);
  const [AA32, AA32S] = useImage(Car[32]);
  const [AA33, AA33S] = useImage(Car[33]);
  const [AA34, AA34S] = useImage(Car[34]);
  const [AA35, AA35S] = useImage(Car[35]);
  const [AA36, AA36S] = useImage(Car[36]);
  const [AA37, AA37S] = useImage(Car[37]);
  const [AA38, AA38S] = useImage(Car[38]);
  const [AA39, AA39S] = useImage(Car[39]);

  const [AA40, AA40S] = useImage(Car[40]);
  const [AA41, AA41S] = useImage(Car[41]);
  const [AA42, AA42S] = useImage(Car[42]);
  const [AA43, AA43S] = useImage(Car[43]);
  const [AA44, AA44S] = useImage(Car[44]);
  const [AA45, AA45S] = useImage(Car[45]);
  const [AA46, AA46S] = useImage(Car[46]);
  const [AA47, AA47S] = useImage(Car[47]);
  const [AA48, AA48S] = useImage(Car[48]);
  const [AA49, AA49S] = useImage(Car[49]);

  const [AA50, AA50S] = useImage(Car[50]);
  const [AA51, AA51S] = useImage(Car[51]);
  const [AA52, AA52S] = useImage(Car[52]);
  const [AA53, AA53S] = useImage(Car[53]);
  const [AA54, AA54S] = useImage(Car[54]);
  const [AA55, AA55S] = useImage(Car[55]);
  const [AA56, AA56S] = useImage(Car[56]);
  const [AA57, AA57S] = useImage(Car[57]);
  const [AA58, AA58S] = useImage(Car[58]);
  const [AA59, AA59S] = useImage(Car[59]);

  const [AA60, AA60S] = useImage(Car[60]);
  const [AA61, AA61S] = useImage(Car[61]);
  const [AA62, AA62S] = useImage(Car[62]);
  const [AA63, AA63S] = useImage(Car[63]);
  const [AA64, AA64S] = useImage(Car[64]);
  const [AA65, AA65S] = useImage(Car[65]);
  const [AA66, AA66S] = useImage(Car[66]);
  const [AA67, AA67S] = useImage(Car[67]);
  const [AA68, AA68S] = useImage(Car[68]);
  const [AA69, AA69S] = useImage(Car[69]);

  const [AA70, AA70S] = useImage(Car[70]);
  const [AA71, AA71S] = useImage(Car[71]);
  const [AA72, AA72S] = useImage(Car[72]);
  const [AA73, AA73S] = useImage(Car[73]);
  const [AA74, AA74S] = useImage(Car[74]);
  const [AA75, AA75S] = useImage(Car[75]);
  const [AA76, AA76S] = useImage(Car[76]);
  const [AA77, AA77S] = useImage(Car[77]);
  const [AA78, AA78S] = useImage(Car[78]);
  const [AA79, AA79S] = useImage(Car[79]);

  const [AA80, AA80S] = useImage(Car[80]);
  const [AA81, AA81S] = useImage(Car[81]);
  const [AA82, AA82S] = useImage(Car[82]);
  const [AA83, AA83S] = useImage(Car[83]);
  const [AA84, AA84S] = useImage(Car[84]);
  const [AA85, AA85S] = useImage(Car[85]);
  const [AA86, AA86S] = useImage(Car[86]);
  const [AA87, AA87S] = useImage(Car[87]);
  const [AA88, AA88S] = useImage(Car[88]);
  const [AA89, AA89S] = useImage(Car[89]);

  const [AA90, AA90S] = useImage(Car[90]);
  const [AA91, AA91S] = useImage(Car[91]);
  const [AA92, AA92S] = useImage(Car[92]);
  const [AA93, AA93S] = useImage(Car[93]);
  const [AA94, AA94S] = useImage(Car[94]);
  const [AA95, AA95S] = useImage(Car[95]);
  const [AA96, AA96S] = useImage(Car[96]);
  const [AA97, AA97S] = useImage(Car[97]);
  const [AA98, AA98S] = useImage(Car[98]);
  const [AA99, AA99S] = useImage(Car[99]);

  const [AA100, AA100S] = useImage(Car[100]);
  const [AA101, AA101S] = useImage(Car[101]);
  const [AA102, AA102S] = useImage(Car[102]);
  const [AA103, AA103S] = useImage(Car[103]);
  const [AA104, AA104S] = useImage(Car[104]);
  const [AA105, AA105S] = useImage(Car[105]);
  const [AA106, AA106S] = useImage(Car[106]);
  const [AA107, AA107S] = useImage(Car[107]);
  const [AA108, AA108S] = useImage(Car[108]);
  const [AA109, AA109S] = useImage(Car[109]);

  const [AA110, AA110S] = useImage(Car[110]);
  const [AA111, AA111S] = useImage(Car[111]);
  const [AA112, AA112S] = useImage(Car[112]);
  const [AA113, AA113S] = useImage(Car[113]);
  const [AA114, AA114S] = useImage(Car[114]);
  const [AA115, AA115S] = useImage(Car[115]);
  const [AA116, AA116S] = useImage(Car[116]);
  const [AA117, AA117S] = useImage(Car[117]);
  const [AA118, AA118S] = useImage(Car[118]);
  const [AA119, AA119S] = useImage(Car[119]);

  const newImages = Array.of(
    [AA0, AA0S],
    [AA1, AA1S],
    [AA2, AA2S],
    [AA3, AA3S],
    [AA4, AA4S],
    [AA5, AA5S],
    [AA6, AA6S],
    [AA7, AA7S],
    [AA8, AA8S],
    [AA9, AA9S],
    [AA10, AA10S],
    [AA11, AA11S],
    [AA12, AA12S],
    [AA13, AA13S],
    [AA14, AA14S],
    [AA15, AA15S],
    [AA16, AA16S],
    [AA17, AA17S],
    [AA18, AA18S],
    [AA19, AA19S],
    [AA20, AA20S],
    [AA21, AA21S],
    [AA22, AA22S],
    [AA23, AA23S],
    [AA24, AA24S],
    [AA25, AA25S],
    [AA26, AA26S],
    [AA27, AA27S],
    [AA28, AA28S],
    [AA29, AA29S],

    [AA30, AA30S],
    [AA31, AA31S],
    [AA32, AA32S],
    [AA33, AA33S],
    [AA34, AA34S],
    [AA35, AA35S],
    [AA36, AA36S],
    [AA37, AA37S],
    [AA38, AA38S],
    [AA39, AA39S],

    [AA40, AA40S],
    [AA41, AA41S],
    [AA42, AA42S],
    [AA43, AA43S],
    [AA44, AA44S],
    [AA45, AA45S],
    [AA46, AA46S],
    [AA47, AA47S],
    [AA48, AA48S],
    [AA49, AA49S],

    [AA50, AA50S],
    [AA51, AA51S],
    [AA52, AA52S],
    [AA53, AA53S],
    [AA54, AA54S],
    [AA55, AA55S],
    [AA56, AA56S],
    [AA57, AA57S],
    [AA58, AA58S],
    [AA59, AA59S],

    [AA60, AA60S],
    [AA61, AA61S],
    [AA62, AA62S],
    [AA63, AA63S],
    [AA64, AA64S],
    [AA65, AA65S],
    [AA66, AA66S],
    [AA67, AA67S],
    [AA68, AA68S],
    [AA69, AA69S],

    [AA70, AA70S],
    [AA71, AA71S],
    [AA72, AA72S],
    [AA73, AA73S],
    [AA74, AA74S],
    [AA75, AA75S],
    [AA76, AA76S],
    [AA77, AA77S],
    [AA78, AA78S],
    [AA79, AA79S],

    [AA80, AA80S],
    [AA81, AA81S],
    [AA82, AA82S],
    [AA83, AA83S],
    [AA84, AA84S],
    [AA85, AA85S],
    [AA86, AA86S],
    [AA87, AA87S],
    [AA88, AA88S],
    [AA89, AA89S],

    [AA90, AA90S],
    [AA91, AA91S],
    [AA92, AA92S],
    [AA93, AA93S],
    [AA94, AA94S],
    [AA95, AA95S],
    [AA96, AA96S],
    [AA97, AA97S],
    [AA98, AA98S],
    [AA99, AA99S],

    [AA100, AA100S],
    [AA101, AA101S],
    [AA102, AA102S],
    [AA103, AA103S],
    [AA104, AA104S],
    [AA105, AA105S],
    [AA106, AA106S],
    [AA107, AA107S],
    [AA108, AA108S],
    [AA109, AA109S],

    [AA110, AA110S],
    [AA111, AA111S],
    [AA112, AA112S],
    [AA113, AA113S],
    [AA114, AA114S],
    [AA115, AA115S],
    [AA116, AA116S],
    [AA117, AA117S],
    [AA118, AA118S],
    [AA119, AA119S]
  );
  return newImages;
};

export default CarArray;
