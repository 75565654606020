import firebase from "firebase/app";
import "firebase/database";
import 'firebase/storage';
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCnRzp620jK7u3goXmCrYPcYbuDTwjd9KY",
  authDomain: "xilver-31abe.firebaseapp.com",
  databaseURL: "https://xilver-31abe-default-rtdb.firebaseio.com",
  projectId: "xilver-31abe",
  storageBucket: "xilver-31abe.appspot.com",
  messagingSenderId: "881945129473",
  appId: "1:881945129473:web:21cea1c1afde24b7d2c600",
  measurementId: "G-WK45B8Z00E"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.database();
const storage = firebase.storage();
firebase.analytics();

export { db, storage };