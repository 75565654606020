import React from 'react';
import data from './data.json';
import Carousel from 'better-react-carousel'
import { Carousel as CarouselMobile} from "react-responsive-carousel";
import { Container } from 'reactstrap';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Row } from 'react-bootstrap';

const SneakerWall = () => {
  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }
  const fluid = true;

  function chunkArray(arr, chunk_size) {
    var results = [];
    var i, j, temparray;
    for (i = 0, j = arr.length; i < j; i += chunk_size) {
      temparray = arr.slice(i, i + chunk_size);
      results.push(temparray);
    }
    return results;
  }

  var shoeArr = chunkArray(data, 6);


  return (
    <>
      <Container style={{marginBottom: mobile? "0" : "20vh"}}>
        <h1 
          style={{
            marginTop: "10vh",
            marginBottom: mobile ? "5vh" : "10vh",
            color: "white"
          }}
          className='font-face-nexa'>
          Sneaker Wall
        </h1>
        {mobile && (
          <CarouselMobile
            autoPlay={true}
            interval={6000}
            showArrows={true}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            infiniteLoop={true}
          >
            {shoeArr.map((sneakers, idx) => {
              return(
                <Container key={idx} fluid={fluid}>
                  <Row>
                    {sneakers.map((sneaker, idx2) => (
                      <img 
                        src={sneaker.Image}
                        key={idx2}
                        style={{
                          pointerEvents: "all",
                          width:"50%",
                          padding:"25px"
                        }} 
                        alt='xilver_sneaker'
                      />
                    ))}
                  </Row>
                </Container>
              )
            })}
          </CarouselMobile>
        )}
        {!mobile && (
          <Carousel cols={4} rows={4} autoPlay={1000} gap={20} showDots={true} hideArrow={false} loop={true}>
            {data.map((sneaker, idx) => {
              return(
                <Carousel.Item key={idx}>
                  <img
                    src={sneaker.Image}
                    width="50%"
                    height="auto"
                    style={{margin:"10px", cursor:"pointer"}} 
                    alt='xilver_sneaker'
                  />
                </Carousel.Item>
              )
            })}
          </Carousel>
        )}
      </Container>
    </>
  );
};

export default SneakerWall;