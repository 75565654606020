import React from 'react';
import { Button } from 'reactstrap';

const Result = props => {
  return (
    <div id="result">
      <h5 style={{color:"white"}}>
        You got <strong>{props.score}</strong> out of{' '}
        <strong>{props.numOfQuestions + 1}</strong> questions correct
      </h5>
      <br/>
      <Button className="next-btn" color='primary' onClick={props.nextQuizHandler}>
        Next Game
      </Button>
    </div>
  );
};

export default Result;
