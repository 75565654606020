import React, {useEffect, useRef, useState} from 'react';
import "./Landing.css";
import * as Scroll from "react-scroll";
import { Container } from 'reactstrap';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useElementOnScreen from '../../hooks/useElementOnScreen';
import * as Icon from 'react-bootstrap-icons';

const Landing = () => {
  const fluid = true;
  const [showScrollDown, setShowScrollDown] = useState(false);
  const { width, height } = useWindowDimensions();
  const [muted, setMuted] = useState(false);

  const toggleScrollDown = (e) => {
    if (e.target.currentTime >= 5) {
      setShowScrollDown(true);
    }
  };

  var mobile, landscape;
  if (width < 768) {
    mobile = true;
    landscape = false;
  } else if (height < 576 || width < 992) {
    mobile = false;
    landscape = true;
  } else {
    mobile = false;
    landscape = false;
  }

  const videoRef = useRef(null);
  // const options = {
  //   root: null,
  //   rootMargin: '0px',
  //   threshold: 0.2
  // }
  // const isVisibile = useElementOnScreen(options, videoRef)

  // useEffect(() => {
  //   console.log(isVisibile)
  //   if (isVisibile) {
  //     videoRef.current.muted=false;
  //     setMuted(false)
  //   }
  //   else {
  //     videoRef.current.muted=true;
  //     setMuted(true)
  //   }
  // }, [isVisibile])

  const scrollDown = () => {
    Scroll.animateScroll.scrollTo(mobile ? 500 : landscape ? 500 : 1000, {
      smooth: true,
    });
  };

  const handleSound = () => {
    if(muted) {
      videoRef.current.muted = false;
      setMuted(false)
    } else {
      videoRef.current.muted = true;
      setMuted(true)
    }
  };

  return (
    <>
      <Container 
        fluid={fluid}
        onClick={() => handleSound()}
        className="section_one p-0 m-0 font-face-nexa"
        style={{minWidth: "100%"}}
      >
        <div className="stage">
          {!muted && (
            <div className="icon pulsing d-flex justify-content-center align-items-center">
              <Icon.VolumeUpFill/>
            </div>
          )}
          {muted && (
            <div className="icon d-flex justify-content-center align-items-center">
              <Icon.VolumeMuteFill/>
            </div>
          )}
        </div>
        <div className="video-landing">
          {!mobile && 
            <video 
              id="video" 
              autoPlay
              muted
              loop
              preload="auto"
              style={{
                zIndex: 1,
                opacity: 0.7
              }}
              onTimeUpdate={(e) => toggleScrollDown(e)}
              ref={videoRef}
              playsInline
              poster={require("../../../assets/images/thumbnails/LandingVideo.jpg")}
              position="relative"
            >
              <source 
                src={require("../../../assets/videos/Landing/LandingVideo.mp4")} 
                type="video/mp4"
              />
            </video>
          }
          {mobile && 
            <video 
              id="video" 
              autoPlay
              loop
              muted
              ref={videoRef}
              style={{
                zIndex: 1,
                opacity: 0.7
              }}
              playsInline
              position="relative"
            >
              <source 
                src={require("../../../assets/videos/Landing/LandingVideoMobile.mp4")} 
                type="video/mp4"
              />
            </video>
          }
          {showScrollDown && (
            <div className="scrollDownContainer" onClick={scrollDown}>
              <div className="scrollDownChevron"></div>
              <div className="scrollDownChevron"></div>
              <div className="scrollDownChevron"></div>
              <span className="scrollDownText">Scroll down</span>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default Landing;