import React, {useState} from 'react';
import { Container } from 'reactstrap';
import "./Contact.css";
import "./ContactForm.css";
import {db} from "../../../firebase.js";
import useWindowDimensions from '../../hooks/useWindowDimensions';
import axios from 'axios';

const Contact = () => {
  const fluid = true;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [compName, setCompName] = useState("");
  const [compWebsite, setCompWebsite] = useState("");
  const [message, setMessage] = useState("");
  const [addedContact, setAddedContact] = useState(false);

  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }

  const addContact = (e) => {
    e.preventDefault();

    axios({
      method: "POST", 
      url: "https://mystry-server-z574yasx7q-uc.a.run.app/sendContactXilver", 
      data: ({
          name: name,   
          email: email,  
          compName: compName,
          compWebsite: compWebsite,
          message: message
      })
    }).then((response)=>{
        document.getElementById('contact-form').reset();
  })

    db.ref("contact").push({
      name: name,
      email: email,
      companyName: compName,
      companyWebsite: compWebsite,
      message: message
    }).catch(alert);
    setAddedContact(true) 
    setName("")
    setEmail("")
    setCompName("")
    setCompWebsite("")
    setMessage("")
  };
  
  return (
    <>
     <Container 
      fluid={fluid}
      className="p-0 m-0 font-face-nexa"
      style={{minWidth: "100%"}}
    >
      <div className="video-block">
        <video 
          id="video" 
          autoPlay
          loop
          muted
          poster={require('../../../assets/images/thumbnails/Contact.png')}
          playsInline
          preload="auto"
          className='darken'
        >
          {/* <source
            src={
              require("../../../assets/videos/Contact/ContactHevc.hevc.mp4")
            }
            type="video/mp4; codecs=hvc1"
          ></source> */}
          <source src={require("../../../assets/videos/Contact/Contact.mp4")} type="video/mp4"/>
        </video>
        <div className="contact container hero">
            <span style={{fontSize: mobile? "3rem" : "6rem"}}>Contact Us</span>
        </div>
        <div className="container">
          <section className="contact" id="contact">
            <div className="row clearfix" >
              <div className="offset-sm-2 col-sm-8">
                <form id="contact-form" onSubmit={addContact}>
                  <div className="form-group d-flex justify-content-center">
                    <input 
                      type="text" 
                      name="name" 
                      className="form-control" 
                      placeholder='Name'
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group d-flex justify-content-center">
                    <input 
                      type="email" 
                      name="email" 
                      className="form-control" 
                      placeholder='Email'
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group d-flex justify-content-center">
                    <input 
                      type="text" 
                      name="text"
                      className="form-control" 
                      placeholder='Company Name'
                      required
                      onChange={(e) => setCompName(e.target.value)}
                    />
                  </div>
                  <div className="form-group d-flex justify-content-center">
                    <input 
                      type="text" 
                      name="website" 
                      className="form-control" 
                      placeholder='Company Website'
                      required
                      onChange={(e) => setCompWebsite(e.target.value)}
                    />
                  </div>
                  <div className="form-group d-flex justify-content-center">
                    <textarea 
                      className="form-control text-area"
                      name="message"
                      placeholder='Message'
                      required
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  <div className="form-group d-flex justify-content-center">
                    <button className="btn submit_btn pt-3">Submit</button>
                  </div>
                </form>
                {addedContact && <h3 className='contact-message font-face-nexa'>
                  Thanks for contacting us, will get back to you soon!
                </h3>}
              </div>
            </div>
          </section>
        </div>
      </div>
     </Container>
    </>
  );
};
export default Contact;