import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { db } from '../../../firebase';
import './Blog.css';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Comment = (props) => {

  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }

  const id = Number(props.id) + 1;
  const getCommentsFromFirebase = () => {
    const ref = db.ref("blog-comments");
    var comments = [];
    ref.on('value', (snapshot) => {
      comments = snapshot.child(`blog${id}`).val()
    })
    const commentsArray = Object.values(comments);
    return commentsArray;
  }

  const [comments, setComments] = useState(getCommentsFromFirebase());
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    const fetchData = () => {
      const ref = db.ref("blog-comments");
      ref.on('value', (snapshot) => {
        const fetched = snapshot.child(`blog${id}`).val();
        const commentsArray = Object.values(fetched);
        setComments(commentsArray);
      }); 
    };
    fetchData();
  }, [id]);

  const pushCommentToFirebase = (e) => {
    e.preventDefault();
    const ref = db.ref("blog-comments");
    ref.child(`blog${id}`).push({
      name: name,
      email: email,
      comment: newComment,
      date: new Date().toLocaleDateString()
    });
    setName("");
    setEmail("");
    setNewComment("")
    document.getElementById('comment-form').reset();
  }

  return (
    <>
      <Container 
        className='d-flex justify-content-center font-face-nexa' 
        style={{marginBottom: mobile? "10vh": "15vh"}}
      >
        <div style={{width: mobile? "85%": "50%"}}>
          <h3 style={{
            color: "white",
            marginBottom: "5vh"
          }}>
            Write a Comment
          </h3>
          <form onSubmit={pushCommentToFirebase} id='comment-form'>
            <Row>
              <Col md={6}>
                <div className="form-group">
                  <input 
                    className="form-control status-box" 
                    placeholder="Name*"
                    onChange={(e) => setName(e.target.value)}
                    required
                  ></input>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <input 
                    className="form-control status-box" 
                    placeholder="Email (Optional)"
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                </div>
              </Col>
            </Row>
            <div className="form-group">
              <textarea 
                className="form-control" 
                rows="3" placeholder="Comment*"
                onChange={(e) => setNewComment(e.target.value)}
                required
              ></textarea>
            </div>
            <div className="button-group pull-between">
              <button type="submit" className="btn-xilver">
                Post
              </button>
            </div>
          </form>
          <div style={{marginTop: mobile? "10vh": "15vh", color:"white"}}>
            <h3 className='d-flex flex-start align-items-start all-comments'>
              {comments.length}{" "}
              {comments.length === 1 ? "Comment" : "Comments"}
            </h3>
            {comments.map((user, id) => {
              return(
                <div className="card comments-data" key={id}>
                  <div className="card-body">
                    <div className="d-flex flex-start align-items-center">
                      <img className="rounded-circle shadow-1-strong me-3"
                        src={`https://ui-avatars.com/api/name=${user.name}&background=random`} alt="avatar" width="60"
                        height="60" />
                      <div>
                        <h6 className="fw-bold mb-1">{user.name}</h6>
                        <p className="text-muted small mb-0">
                          Shared publicly - {user.date}
                        </p>
                      </div>
                    </div>

                    <p className="mt-3 mb-4 pb-2">
                      {user.comment}
                    </p>

                    {/* <div className="small d-flex justify-content-start">
                      <div className="d-flex align-items-center me-3" 
                        style={{cursor:"pointer"}}
                      >
                        <i className="far fa-thumbs-up me-3" style={{color: "white"}}></i>
                        <p className="mb-0" style={{color: "lightgreen", fontSize:"1rem"}}>
                          Like
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Comment;