import { shuffle } from "./Shuffle";

export const quizzes = [
  {
    title: 'Guess the Collab',
    questions: shuffle([
      {
        correctAnswer: 'Off-White x Air Force 1 Low 07 MCA',
        incorrectAnswers: ['Off-White x Air Jordan 1 Retro High OG White', 'Off-White x Air Jordan 1 Retro High OG Chicago', 'Off-White x Air Max 90 Desert Ore'],
        imageUrl: require("../../../assets/images/sneaker-collab/3.webp")
      },
      {
        correctAnswer: 'Travis Scott x Air Jordan 6 Retro Olive',
        incorrectAnswers: ['Travis Scott x Air Jordan 1 Retro High OG Mocha', 'Travis Scott x Air Max 1 Baroque Brown', 'Fragment Design x Travis Scott x Air Jordan 1 Retro High'],
        imageUrl: require("../../../assets/images/sneaker-collab/2.webp")
      },
      {
        correctAnswer: 'Travis Scott x Air Jordan 1 Retro High OG Mocha',
        incorrectAnswers: ['Travis Scott x Air Max 1 Baroque Brown', 'Fragment Design x Travis Scott x Air Jordan 1 Retro High', 'Travis Scott x Air Jordan 6 Retro Olive'],
        imageUrl: require("../../../assets/images/sneaker-collab/1.webp")
      },
      {
        correctAnswer: 'Off-White x Air Max 90 Desert Ore',
        incorrectAnswers: ['Off-White x Air Force 1 Low 07 MCA', 'Off-White x Air Jordan 1 Retro High OG Chicago', 'Off-White x Air Jordan 1 Retro High OG White'],
        imageUrl: require("../../../assets/images/sneaker-collab/4.webp")
      },
      {
        correctAnswer: 'Travis Scott x Air Force 1 Low Cactus Jack',
        incorrectAnswers: ['Travis Scott x Air Max 1 Baroque Brown', 'CLOT x Air Jordan 1 Mid Fearless', 'Off-White x Air Jordan 1 Retro High OG Chicago'],
        imageUrl: require("../../../assets/images/sneaker-collab/5.webp")
      },
      {
        correctAnswer: 'Supreme x Air Force 1 Low Box Logo',
        incorrectAnswers: ['Supreme x Dunk Low OG SB QS Barkroot Brown', 'Supreme x Dunk SB Low Varsity Red', 'Louis Vuitton x Air Force x Off White '],
        imageUrl: require("../../../assets/images/sneaker-collab/16.webp")
      },
      {
        correctAnswer: 'Melody Ehsani x Wmns Air Jordan 1 Mid Fearless',
        incorrectAnswers: ['CLOT x Air Jordan 1 Mid Fearless', 'Travis Scott x Air Max 1 Baroque Brown', 'Travis Scott x Air Force 1 Low Cactus Jack'],
        imageUrl: require("../../../assets/images/sneaker-collab/7.webp")
      },
      {
        correctAnswer: 'Supreme x Dunk SB Low Varsity Red',
        incorrectAnswers: ['Travis Scott x Air Max 1 Baroque Brown', 'Supreme x Dunk Low OG SB QS Barkroot Brown', 'Off-White x Air Jordan 1 Retro High OG UNC'],
        imageUrl: require("../../../assets/images/sneaker-collab/8.webp")
      },
      {
        correctAnswer: 'Fragment Design x Travis Scott x Air Jordan 1 Retro Low',
        incorrectAnswers: ['Fragment Design x Travis Scott x Air Jordan 1 Retro High', 'Bad Bunny x Forum Buckle Low Blue Tint Puerto Rico Exclusive', 'Travis Scott x Air Force 1 Low Cactus Jack'],
        imageUrl: require("../../../assets/images/sneaker-collab/9.webp")
      },
      {
        correctAnswer: 'Fragment Design x Travis Scott x Air Jordan 1 Retro High',
        incorrectAnswers: ['Fragment Design x Travis Scott x Air Jordan 1 Retro Low', 'Bad Bunny x Forum Buckle Low Blue Tint Puerto Rico Exclusive', 'Travis Scott x Air Force 1 Low Cactus Jack'],
        imageUrl: require("../../../assets/images/sneaker-collab/10.webp")
      },
    ]),
  },
  {
    title: 'Guess the Collab',
    questions: shuffle([
      {
        correctAnswer: 'Travis Scott x Air Max 1 Baroque Brown',
        incorrectAnswers: ['Dior x Air Jordan 1 High', 'Off-White x Air Jordan 1 Retro High OG UNC', 'CLOT x Air Jordan 1 Mid Fearless'],
        imageUrl: require("../../../assets/images/sneaker-collab/11.webp")
      },
      {
        correctAnswer: 'Bad Bunny x Forum Buckle Low Blue Tint Puerto Rico Exclusive',
        incorrectAnswers: ['Comme des Garçons x Chuck Taylor All Star', 'Melody Ehsani x Wmns Air Jordan 1 Mid Fearless', 'Off-White x Air Force 1 Low 07 MCA'],
        imageUrl: require("../../../assets/images/sneaker-collab/12.webp")
      },
      {
        correctAnswer: 'Off-White x Air Jordan 1 Retro High OG UNC',
        incorrectAnswers: ['Off-White x Air Jordan 1 Retro High OG Chicago', 'Off-White x Air Max 90 Desert Ore', 'Off-White x Air Force 1 Low 07 MCA'],
        imageUrl: require("../../../assets/images/sneaker-collab/13.webp")
      },
      {
        correctAnswer: 'Off-White x Air Jordan 1 Retro High OG Chicago',
        incorrectAnswers: ['Off-White x Air Jordan 1 Retro High OG UNC', 'Off-White x Air Max 90 Desert Ore', 'Off-White x Air Force 1 Low 07 MCA'],
        imageUrl: require("../../../assets/images/sneaker-collab/14.webp")
      },
      {
        correctAnswer: 'Off-White x Air Jordan 1 Retro High OG White',
        incorrectAnswers: ['Off-White x Air Jordan 1 Retro High OG UNC', 'Off-White x Air Max 90 Desert Ore', 'Off-White x Air Jordan 1 Retro High OG Chicago'],
        imageUrl: require("../../../assets/images/sneaker-collab/15.webp")
      },
      {
        correctAnswer: 'Supreme x Dunk Low OG SB QS Barkroot Brown',
        incorrectAnswers: ['Supreme x Dunk SB Low Varsity Red', 'Off-White x Air Max 90 Desert Ore', 'Supreme x Air Force 1 Low Box Logo'],
        imageUrl: require("../../../assets/images/sneaker-collab/17.webp")
      },
      {
        correctAnswer: 'Louis Vuitton x Air Force x Off White',
        incorrectAnswers: ['Comme des Garçons x Chuck Taylor All Star', 'Melody Ehsani x Wmns Air Jordan 1 Mid Fearless', 'Off-White x Air Jordan 1 Retro High OG Chicago'],
        imageUrl: require("../../../assets/images/sneaker-collab/18.webp")
      },
      {
        correctAnswer: 'Dior x Air Jordan 1 High',
        incorrectAnswers: ['Louis Vuitton x Air Force x Off White', 'Off-White x Air Max 90 Desert Ore', 'Comme des Garçons x Chuck Taylor All Star'],
        imageUrl: require("../../../assets/images/sneaker-collab/19.webp")
      },
      {
        correctAnswer: 'Comme des Garçons x Chuck Taylor All Star',
        incorrectAnswers: ['Dior x Air Jordan 1 High', 'CLOT x Air Jordan 1 Mid Fearless', 'Travis Scott x Air Force 1 Low Cactus Jack'],
        imageUrl: require("../../../assets/images/sneaker-collab/20.webp")
      },
      {
        correctAnswer: 'Fragment Design x Travis Scott x Air Jordan 1 Retro Low',
        incorrectAnswers: ['Fragment Design x Travis Scott x Air Jordan 1 Retro High', 'Bad Bunny x Forum Buckle Low Blue Tint Puerto Rico Exclusive', 'Travis Scott x Air Force 1 Low Cactus Jack'],
        imageUrl: require("../../../assets/images/sneaker-collab/9.webp")
      }
    ]),
  },
  {
    title: 'Guess the Collab',
    questions: shuffle([
      {
        correctAnswer: 'Travis Scott x Air Max 1 Baroque Brown',
        incorrectAnswers: ['Dior x Air Jordan 1 High', 'Off-White x Air Jordan 1 Retro High OG UNC', 'CLOT x Air Jordan 1 Mid Fearless'],
        imageUrl: require("../../../assets/images/sneaker-collab/11.webp")
      },
      {
        correctAnswer: 'Bad Bunny x Forum Buckle Low Blue Tint Puerto Rico Exclusive',
        incorrectAnswers: ['Comme des Garçons x Chuck Taylor All Star', 'Melody Ehsani x Wmns Air Jordan 1 Mid Fearless', 'Off-White x Air Force 1 Low 07 MCA'],
        imageUrl: require("../../../assets/images/sneaker-collab/12.webp")
      },
      {
        correctAnswer: 'Off-White x Air Jordan 1 Retro High OG UNC',
        incorrectAnswers: ['Off-White x Air Jordan 1 Retro High OG Chicago', 'Off-White x Air Max 90 Desert Ore', 'Off-White x Air Force 1 Low 07 MCA'],
        imageUrl: require("../../../assets/images/sneaker-collab/13.webp")
      },
      {
        correctAnswer: 'Off-White x Air Jordan 1 Retro High OG Chicago',
        incorrectAnswers: ['Off-White x Air Jordan 1 Retro High OG UNC', 'Off-White x Air Max 90 Desert Ore', 'Off-White x Air Force 1 Low 07 MCA'],
        imageUrl: require("../../../assets/images/sneaker-collab/14.webp")
      },
      {
        correctAnswer: 'Off-White x Air Jordan 1 Retro High OG White',
        incorrectAnswers: ['Off-White x Air Jordan 1 Retro High OG UNC', 'Off-White x Air Max 90 Desert Ore', 'Off-White x Air Jordan 1 Retro High OG Chicago'],
        imageUrl: require("../../../assets/images/sneaker-collab/15.webp")
      },
      {
        correctAnswer: 'Supreme x Air Force 1 Low Box Logo',
        incorrectAnswers: ['Supreme x Dunk Low OG SB QS Barkroot Brown', 'Supreme x Dunk SB Low Varsity Red', 'Louis Vuitton x Air Force x Off White '],
        imageUrl: require("../../../assets/images/sneaker-collab/16.webp")
      },
      {
        correctAnswer: 'Melody Ehsani x Wmns Air Jordan 1 Mid Fearless',
        incorrectAnswers: ['CLOT x Air Jordan 1 Mid Fearless', 'Travis Scott x Air Max 1 Baroque Brown', 'Travis Scott x Air Force 1 Low Cactus Jack'],
        imageUrl: require("../../../assets/images/sneaker-collab/7.webp")
      },
      {
        correctAnswer: 'Supreme x Dunk SB Low Varsity Red',
        incorrectAnswers: ['Travis Scott x Air Max 1 Baroque Brown', 'Supreme x Dunk Low OG SB QS Barkroot Brown', 'Off-White x Air Jordan 1 Retro High OG UNC'],
        imageUrl: require("../../../assets/images/sneaker-collab/8.webp")
      },
      {
        correctAnswer: 'Fragment Design x Travis Scott x Air Jordan 1 Retro Low',
        incorrectAnswers: ['Fragment Design x Travis Scott x Air Jordan 1 Retro High', 'Bad Bunny x Forum Buckle Low Blue Tint Puerto Rico Exclusive', 'Travis Scott x Air Force 1 Low Cactus Jack'],
        imageUrl: require("../../../assets/images/sneaker-collab/9.webp")
      },
      {
        correctAnswer: 'Fragment Design x Travis Scott x Air Jordan 1 Retro High',
        incorrectAnswers: ['Fragment Design x Travis Scott x Air Jordan 1 Retro Low', 'Bad Bunny x Forum Buckle Low Blue Tint Puerto Rico Exclusive', 'Travis Scott x Air Force 1 Low Cactus Jack'],
        imageUrl: require("../../../assets/images/sneaker-collab/10.webp")
      },
    ]),
  },
  {
    title: 'Guess the Collab',
    questions: shuffle([
      {
        correctAnswer: 'Off-White x Air Force 1 Low 07 MCA',
        incorrectAnswers: ['Off-White x Air Jordan 1 Retro High OG White', 'Off-White x Air Jordan 1 Retro High OG Chicago', 'Off-White x Air Max 90 Desert Ore'],
        imageUrl: require("../../../assets/images/sneaker-collab/3.webp")
      },
      {
        correctAnswer: 'Travis Scott x Air Jordan 6 Retro Olive',
        incorrectAnswers: ['Travis Scott x Air Jordan 1 Retro High OG Mocha', 'Travis Scott x Air Max 1 Baroque Brown', 'Fragment Design x Travis Scott x Air Jordan 1 Retro High'],
        imageUrl: require("../../../assets/images/sneaker-collab/2.webp")
      },
      {
        correctAnswer: 'Travis Scott x Air Jordan 1 Retro High OG Mocha',
        incorrectAnswers: ['Travis Scott x Air Max 1 Baroque Brown', 'Fragment Design x Travis Scott x Air Jordan 1 Retro High', 'Travis Scott x Air Jordan 6 Retro Olive'],
        imageUrl: require("../../../assets/images/sneaker-collab/1.webp")
      },
      {
        correctAnswer: 'Off-White x Air Max 90 Desert Ore',
        incorrectAnswers: ['Off-White x Air Force 1 Low 07 MCA', 'Off-White x Air Jordan 1 Retro High OG Chicago', 'Off-White x Air Jordan 1 Retro High OG White'],
        imageUrl: require("../../../assets/images/sneaker-collab/4.webp")
      },
      {
        correctAnswer: 'Travis Scott x Air Force 1 Low Cactus Jack',
        incorrectAnswers: ['Travis Scott x Air Max 1 Baroque Brown', 'CLOT x Air Jordan 1 Mid Fearless', 'Off-White x Air Jordan 1 Retro High OG Chicago'],
        imageUrl: require("../../../assets/images/sneaker-collab/5.webp")
      },
      {
        correctAnswer: 'Off-White x Air Jordan 1 Retro High OG White',
        incorrectAnswers: ['Off-White x Air Jordan 1 Retro High OG UNC', 'Off-White x Air Max 90 Desert Ore', 'Off-White x Air Jordan 1 Retro High OG Chicago'],
        imageUrl: require("../../../assets/images/sneaker-collab/15.webp")
      },
      {
        correctAnswer: 'Supreme x Dunk Low OG SB QS Barkroot Brown',
        incorrectAnswers: ['Supreme x Dunk SB Low Varsity Red', 'Off-White x Air Max 90 Desert Ore', 'Supreme x Air Force 1 Low Box Logo'],
        imageUrl: require("../../../assets/images/sneaker-collab/17.webp")
      },
      {
        correctAnswer: 'Louis Vuitton x Air Force x Off White',
        incorrectAnswers: ['Comme des Garçons x Chuck Taylor All Star', 'Melody Ehsani x Wmns Air Jordan 1 Mid Fearless', 'Off-White x Air Jordan 1 Retro High OG Chicago'],
        imageUrl: require("../../../assets/images/sneaker-collab/18.webp")
      },
      {
        correctAnswer: 'Dior x Air Jordan 1 High',
        incorrectAnswers: ['Louis Vuitton x Air Force x Off White', 'Off-White x Air Max 90 Desert Ore', 'Comme des Garçons x Chuck Taylor All Star'],
        imageUrl: require("../../../assets/images/sneaker-collab/19.webp")
      },
      {
        correctAnswer: 'Comme des Garçons x Chuck Taylor All Star',
        incorrectAnswers: ['Dior x Air Jordan 1 High', 'CLOT x Air Jordan 1 Mid Fearless', 'Travis Scott x Air Force 1 Low Cactus Jack'],
        imageUrl: require("../../../assets/images/sneaker-collab/20.webp")
      },
    ]),
  },
];
