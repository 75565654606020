import React, { Suspense, useEffect, useState } from 'react';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';
import Landing from '../Landing/Landing';
import LandingSubsection from '../Landing/LandingSubsection';
import Partner from '../Partner/Partner';
import Quiz from '../SneakerCollab/Quiz';
import Subscription from '../Subscription/Subscription';
import MainVideo from './MainVideo';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import NavbarMobile from '../Navbar/NavbarMobile';
import QuizMobile from '../SneakerCollab/QuizMobile';
import SneakerWall from '../SneakerWall/SneakerWall';
import Artists_Brand from '../Artists_Brand/Artists_Brand';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import CarSection from '../CarSection/CarSection';
import Sequence from '../Sequence/Sequence';
import Spotlight from '../Spotlight/Spotlight';
import { useHistory } from 'react-router-dom';

const Main = () => {
  const [open, setOpen] = useState(false);
  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }

  const openLanding = () => {
    setOpen(true);
    sessionStorage.setItem('openstorage', open);
  }

  useEffect(() => {
    window.addEventListener("unload", removeSessionStorage);
    return () => {
      window.removeEventListener("unload", removeSessionStorage);
    };
  }, []);

  const removeSessionStorage = () => {
    sessionStorage.removeItem('openstorage')
  };

  const history = useHistory()

  // function iOS() {
  //   return [
  //     'iPad Simulator',
  //     'iPhone Simulator',
  //     'iPod Simulator',
  //     'iPad',
  //     'iPhone',
  //     'iPod'
  //   ].includes(navigator.platform)
  //   || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  // }
  // const [isIOS, setIsIOS] = useState(iOS());

  // const [displayContent, setDisplayContent] = useState(false);
  // const [displayContent2, setDisplayContent2] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setDisplayContent(true);
  //   }, 2500);
  // }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setDisplayContent2(true);
  //   }, 5000);
  // }, []);

  return (
    <div>
      {mobile && (
        <>
          {!open && !sessionStorage.getItem('openstorage') && (
            <>
              <div onClick={() => openLanding()}>
                <MainVideo/>
              </div>
            </>
          )}
          {(open || sessionStorage.getItem('openstorage')) && (
            <div>
              <NavbarMobile/>
              <Landing/>
              <LandingSubsection/>
              <LazyLoadComponent threshold={300}>
                <Subscription/>
                <Artists_Brand/>
                <QuizMobile/>
                <SneakerWall/>
                <CarSection/>
                <Sequence/>
                <Partner/>
                <Spotlight/>
                <Contact/>
                <Footer/>
              </LazyLoadComponent>
            </div>
          )}
        </>
      )}
      {!mobile && (
        <>
          {!open && !sessionStorage.getItem('openstorage') && (
            <>
              <div onClick={() => openLanding()}>
                <MainVideo/>
              </div>
            </>
          )}
          {(open || sessionStorage.getItem('openstorage')) && (
            <>
              <Landing/>
              <LandingSubsection/>
              <LazyLoadComponent threshold={500}>
                <Subscription/>
                <Artists_Brand/>
                <LazyLoadComponent threshold={400}>
                  <Quiz/>
                  <SneakerWall/>
                  <CarSection/>
                  <Sequence/>
                  <Partner/>
                  <Spotlight/>
                  <Contact/>
                </LazyLoadComponent>
              </LazyLoadComponent>
              <Footer/>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default Main;