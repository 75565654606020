import './App.css';
import { Route, BrowserRouter as Router } from 'react-router-dom'  
import Main from './components/pages/Main/Main';
import About from './components/pages/About/About';
import Blog from './components/pages/Blogs/Blog';
import ScrollToTop from 'react-router-scroll-top'
import ContactUsPage from './components/pages/Contact/ContactUsPage';

function App() {
  return (
    <Router>  
      <ScrollToTop>
        <div className="App">  
          <Route exact path="/" component={Main} />  
          <Route exact path="/blog/:id" 
            render={(props) => <Blog {...props} />} 
          />  
          <Route exact path="/about" component={About} />  
          <Route exact path="/contact" component={ContactUsPage} />  
        </div> 
      </ScrollToTop> 
    </Router>  
  );
}

export default App;
