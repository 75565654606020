import React from 'react';
import Footer from '../Footer/Footer';
import NavbarDesktop from '../Navbar/Navbar';
import Contact from './Contact';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import NavbarMobile from '../Navbar/NavbarMobile';

const ContactUsPage = () => {
  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }

  return (
    <>
      {mobile? <NavbarMobile/>: <NavbarDesktop/>}
      <Contact/>
      <Footer/>
    </>
  );
};

export default ContactUsPage;