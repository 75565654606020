import React from 'react';
import { Container } from 'reactstrap';
import Cursor from 'react-cursor-follow';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import './Main.css'

const MainVideo = () => {
  const fluid = true;
  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }

  return (
    <>
      {mobile && (
        <video 
          id="video-mobile" 
          autoPlay
          muted
          data-hover="click"
          playsInline
        >
          <source
            src={require("../../../assets/videos/XMobile.mp4")} 
            type="video/mp4"
          />
        </video>
      )}
      {!mobile && (
        <Container 
          fluid={fluid}
          style={{minWidth: "100%"}}
        >
          <div className="video-block">
            <Cursor
              pulse
              color="#696969"
              duration={1}
              size={110}
              hollow
            >
              <span className='font-face-nexa'>
                <br/>Click<br/>on X<br/>
              </span>
            </Cursor>
            <video 
              id="video" 
              autoPlay
              muted
              data-hover="click"
              color='white'
              playsInline
            >
              <source
                src={require("../../../assets/videos/X.mp4")} 
                type="video/mp4"
              />
            </video>
          </div>
        </Container>
      )}
    </>
  );
};
export default MainVideo;