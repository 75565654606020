import React, {useState}  from 'react';
import { Container, Row, Col } from "reactstrap";
import "./Subscription.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {db} from "../../../firebase.js";
import { toast } from "react-toastify";

const Subscription = () => {
    const { width, height } = useWindowDimensions();
    var mobile;
    if (width < 768) {
      mobile = true;
    } else if (height < 576 || width < 992) {
      mobile = false;
    } else {
      mobile = false;
    }
    const fluid = true;
    const [userPhone, setUserPhone] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userSubscribed, setUserSubscribed] = useState(false);

    const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const addSubscriber = (e) => {
      e.preventDefault();
      if (!emailRegex.test(String(userEmail).toLowerCase())) {
        toast.error("Please enter a valid email address");
      } else {
        fetch(
          // "http://localhost:4500/sendMailXilver",
          "https://mystry-server-z574yasx7q-uc.a.run.app/sendMailXilver",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: userEmail,
              phone: userPhone,
            }),
          }
        );
      }
      db.ref("users").push({
        email: userEmail,
        phone: userPhone
      }).catch(alert);
      setUserSubscribed(true);
      setUserEmail("");
      setUserPhone("");
    };

    return (
      <>
        {mobile && (
          <Container
            fluid={fluid}
            style={{
              marginTop: "25px",
              marginBottom: "50px",
            }}
          >
            <>
              <Col>
                <Row>
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    width="80%"
                    poster={require('../../../assets/images/thumbnails/Subscription.png')}
                  >
                    <source src={require("../../../assets/videos/Subscription/subscription_mobile.mp4")} type="video/mp4"/>
                  </video>
                </Row>
                <Row>
                  <div className="subs-wrapper">
                    <div className="subs-content">
                      {!mobile 
                      ? <p className='sub-text font-face-nexa text-white'>
                            Sign up for exclusive access to some of the most exciting upcoming collabs in the fashion industry 
                        </p>
                      : <p className='sub-text-mobile font-face-nexa text-white'>
                            Sign up for exclusive access to some of the most exciting upcoming collabs in the fashion industry 
                        </p>
                      }
                      <form className="subs-form mb-4" id="form-subs" onSubmit={addSubscriber}>
                        <input 
                          type="text" 
                          placeholder="Email Address*" 
                          className="subs-field"
                          value={userEmail}
                          required
                          onChange={(e) => setUserEmail(e.target.value)}
                        />
                        <PhoneInput
                          containerStyle={{
                            border: "10px solid black",
                          }}
                          inputStyle={{
                            background: "black",
                            boxShadow: "none",
                            color: "white"
                          }}
                          inputProps={{
                            required: true,
                          }}
                          value={userPhone}
                          onChange={setUserPhone}
                          placeholder="Enter Phone Number"
                          country={"us"}  
                        />
                        <input type="submit" value="Subscribe" className='subs-button font-face-nexa' id="form_submit"></input>
                      </form>
                      {userSubscribed && <h3 className='sub-text-subscribe font-face-nexa'>Thanks for Subscribing!</h3>}
                    </div>
                  </div>
                </Row>
              </Col>
            </>
          </Container>
        )}
        {!mobile && (
          <Container
            fluid={fluid}
            style={{
              marginTop: "50px",
              marginBottom: "50px",
            }}
          >
            <>
              <Row>
                <Col xs={6} md={6}>
                  <div className="subs-wrapper">
                    <div className="subs-content">
                      {!mobile 
                      ? <p className='sub-text font-face-nexa text-white'>
                            Sign up for exclusive access to some of the most exciting upcoming collabs in the fashion industry 
                        </p>
                      : <p className='sub-text-mobile font-face-nexa text-white'>
                            Sign up for exclusive access to some of the most exciting upcoming collabs in the fashion industry 
                        </p>
                      }
                      <form className="subs-form mb-4" id="form-subs" onSubmit={addSubscriber}>
                        <input 
                          type="text" 
                          placeholder="Email Address*" 
                          className="subs-field"
                          value={userEmail}
                          required
                          onChange={(e) => setUserEmail(e.target.value)}
                        />
                        <PhoneInput
                          containerStyle={{
                            border: "10px solid black",
                          }}
                          inputStyle={{
                            background: "black",
                            boxShadow: "none",
                            color: "white"
                          }}
                          inputProps={{
                            required: true,
                          }}
                          value={userPhone}
                          onChange={setUserPhone}
                          placeholder="Enter Phone Number"
                          country={"us"}  
                        />
                        <input type="submit" value="Subscribe" className='subs-button font-face-nexa' id="form_submit"></input>
                      </form>
                      {userSubscribed && <h3 className='sub-text-subscribe font-face-nexa'>Thanks for Subscribing!</h3>}
                    </div>
                  </div>
                </Col>
                <Col xs={6} md={6}>
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    width="80%"
                    poster={require('../../../assets/images/thumbnails/Subscription.png')}
                  >
                    <source
                      src={
                        require("../../../assets/videos/Subscription/subscription.hevc.mp4")
                      }
                      type="video/mp4; codecs=hvc1"
                    />
                    <source 
                      src={
                        require("../../../assets/videos/Subscription/subscription.mp4")
                      } 
                      type="video/mp4"
                    />
                  </video>
                </Col>
              </Row>
            </>
          </Container>
        )}
      </>
    );
};

export default Subscription;