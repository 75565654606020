import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useHistory } from 'react-router-dom';
import './NavbarMobile.css';

function NavbarMobile() {
  const expand = false;
  const history = useHistory();

  return (
    <>
      <Navbar key={expand} bg="light" expand={expand} className='navbar-mobile'>
        <Container fluid>
          <div className="row">
            <div className="col d-flex align-items-center" onClick={() => history.push("/")}>
              <img 
                className="img-fluid d-flex align-items-center p-1" alt="xilver_logo" width="25%"
                src={require("../../../assets/images/xilver-logo-black.png")}
              />
              <Navbar.Brand 
                className='mt-2 p-2 font-face-nexa'
                style={{
                  fontWeight: 900
                }}
              >
                XILVER LABS
              </Navbar.Brand>
            </div>
            <div className="col d-flex justify-content-end align-items-center">
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className='d-flex justify-content-end'/>
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} style={{fontSize: "2rem"}}>
                    Xilver Labs
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body 
                  className='font-face-nexa'
                  style={{
                    marginTop: "50px",
                    fontSize: "25px",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    fontWeight: "800"
                  }}>
                  <Nav>
                    <Nav.Link onClick={() => history.push('/')}>Home</Nav.Link>
                    <Nav.Link onClick={() => history.push('/about')}>About Us</Nav.Link>
                    <Nav.Link onClick={() => history.push('/blog/0')}>Blogs</Nav.Link>
                    <Nav.Link onClick={() => history.push('/contact')}>Contact</Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarMobile;