import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Footer from '../Footer/Footer';
import NavbarDesktop from '../Navbar/Navbar';
import NavbarMobile from '../Navbar/NavbarMobile';
import "./About.css";

const About = () => {
  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }

  const history = useHistory();

  return (
    <div>
      {mobile? <NavbarMobile/>: <NavbarDesktop/>}
      <div>
        <video 
          autoPlay
          loop
          className='about-video'
          poster={require('../../../assets/images/thumbnails/AboutUs.png')}
          muted
          playsInline
        >
          <source
            src={
              require("../../../assets/videos/AboutUs/AboutUs.hevc.mp4")
            }
            type="video/mp4; codecs=hvc1"
          ></source>
          <source src={require("../../../assets/videos/AboutUs/AboutUs.webm")} type="video/webm"/>
          <source src={require("../../../assets/videos/AboutUs/AboutUs.mp4")} type="video/mp4"/>
        </video>
        <div className='about-div d-flex justify-content-center align-items-center'>
          <p className='about-text'>Xilver Labs Pvt. Ltd. is a Concept Collaboration Company dedicated towards showcasing some of the world’s most elusive & evocative collaborations in the world of fashion, film, business, and more.<br/><br/> 
            Xilver Labs was built around the ideology that creative collaborations are going to be one of the key factors in the future of business and partnerships, where we see numerous companies come 
            together on mutual grounds - a world where products are built by several brands for a community of people, rather than a category of people. <br/><br/>  The world of luxury fashion has been/is pivoting towards 
            collaborating with artists, brands, and influencers to harness new creative outlets, but more importantly to tap into the power of of a mutual audience - where consumers associate with not just 
            the brands, but the value of the product in itself. Eg: Off White x Jordan 1, Louis Vuitton x Nike Air Force, Prada x Gucci to name a few. At Xilver Labs, we strive towards fostering such collaborations 
            through licensing deals and one off concept collaborations - We also look forward to creating a new wave of collaborations in several industries outside the realm of fashion - many such concepts are 
            currently in the pipeline.<br/><br/> The second half of the past decade has been a great indicator of the “Power of Collaborations” and we at Xilver Labs look forward towards fostering the next several years of 
            such unique partnerships, as we begin to see a foray of unprecedented collaborations across multiple interdisciplinary industries. 
          </p>
        </div>
      </div>
      <p onClick={() => history.push('/contact')} className='about-contact'><u>Contact Us</u></p>
      <Footer/>
    </div>
  );
};

export default About;