import React from "react";
import {
  StackedCarousel,
  ResponsiveContainer
} from "react-stacked-center-carousel";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import "./Slide.css";
import { Slide } from "./Slide";
import { Container } from "reactstrap";
import useWindowDimensions from '../../hooks/useWindowDimensions';

const data = [
  {
    image: require("../../../assets/videos/CarSection/GT3Rs/GT3Rs.jpg"),
    video: require("../../../assets/videos/CarSection/GT3Rs/GT3Rs.gif"),
    text: "Porsche x Fast & Furious"
  },
  {
    image: require("../../../assets/videos/CarSection/Camaro/Camaro.jpg"),
    video: require("../../../assets/videos/CarSection/Camaro/camaro.gif"),
    text: "Chevrolet x Transformers"
  },
  {
    image: require("../../../assets/videos/CarSection/Lexus/Lexus.jpg"),
    video: require("../../../assets/videos/CarSection/Lexus/Lexus.gif"),
    text: "Lexus x Black Panther"
  },
  {
    image: require("../../../assets/videos/CarSection/G-Wagon/G-Wagon.jpg"),
    video: require("../../../assets/videos/CarSection/G-Wagon/G-Wagon.gif"),
    text: "Mercedes X Die Hard"
  },
  {
    image: require("../../../assets/videos/CarSection/DB5/DB5.webp"),
    video: require("../../../assets/videos/CarSection/DB5/DB5.gif"),
    text: "Aston Martin x James Bond"
  },
];

function Pagination(props) {
  const { centerSlideDataIndex, updatePosition } = props;
  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: mobile? "5px": "10px",
        marginTop: mobile? 0 :20
      }}
    >
      {data.map((_, index) => {
        const isCenterSlide = props.centerSlideDataIndex === index;
        return (
          <div
            onClick={() => {
              updatePosition(index);
            }}
            key={index}
            id="pagination"
            className="pagination-on"
            style={{
              height: 8,
              width: !mobile? (isCenterSlide? 80 : 30): (isCenterSlide? 40 : 20),
              borderRadius: "15px",
              background: isCenterSlide ? "#D3D3D3" : "#5A5A5A",
              border: "1px solid black",
              cursor: "pointer"
            }}
          />
        );
      })}
    </div>
  );
}

const CarSection = () => {
  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }

  const ref = React.useRef(StackedCarousel);
  const [centerSlideDataIndex, setCenterSlideDataIndex] = React.useState(0);
  const onCenterSlideDataIndexChange = (newIndex) => {
    setCenterSlideDataIndex(newIndex);
  };

  const updatePosition = (index) => {
    ref?.current?.swipeTo(index - centerSlideDataIndex);
  };

  return (
    <>
      {mobile && 
        <Container 
          fluid={true}
          className="p-0 mt-4 font-face-comic"
          style={{minWidth: "100%"}}
        >
          <div className="video-block">
            <video 
              id="video" 
              autoPlay
              loop
              playsInline
              muted
            >
              <source src={require("../../../assets/videos/CarSection/CarSectionMobile.mp4")} type="video/mp4"/>
            </video>
          </div>
        </Container>
      }
      {!mobile && 
        <Container 
          fluid={true}
          className="p-0 m-0 font-face-comic"
          style={{minWidth: "100%"}}
        >
          <div className="video-block">
            <video 
              id="video" 
              autoPlay
              loop
              playsInline
              muted
              poster={require('../../../assets/images/thumbnails/CarSection.png')}
            >
              <source src={require("../../../assets/videos/CarSection/CarSection.mp4")} type="video/mp4"/>
            </video>
          </div>
        </Container>
      }
      <Container
        fluid={true}
        style={{
          marginTop: mobile? "2vh": "20vh",
          marginBottom: mobile? "2vh": "25vh"
        }}
      >
       <div className="d-flex justify-content-center align-items-center">
          <h1 
            style={{
              marginBottom: mobile? "5vh": "8vh",
              marginTop: mobile? "8vh": "0vh",
              color: "white",
            }}
            className='font-face-nexa'>
            Movies <span style={{fontWeight: "900"}}>x</span> Brands
          </h1>
       </div>
        <div className="card">
          <div style={{ width: "100%", position: "relative" }}>
            <ResponsiveContainer
              carouselRef={ref}
              render={(width, carouselRef) => {
                return (
                  <StackedCarousel
                    ref={carouselRef}
                    slideComponent={Slide}
                    slideWidth={mobile? width :525}
                    carouselWidth={width}
                    data={data}
                    maxVisibleSlide={mobile? 1:3}
                    disableSwipe
                    customScales={mobile? [1,0.2] :[1, 0.7, 0.3]}
                    transitionTime={450}
                    onActiveSlideChange={onCenterSlideDataIndexChange}
                  />
                );
              }}
            />
            <Fab
              className="card-button left"
              size="small"
              onClick={() => ref.current?.goBack()}
            >
              <KeyboardArrowLeftIcon 
                style={{
                  fontSize: !mobile? "100px": "36px",
                  color: "white",
                  zIndex: "1"
                }}
              />
            </Fab>
            <Fab
              className="card-button right"
              size="small"
              onClick={() => ref.current?.goNext()}
            >
              <KeyboardArrowRightIcon 
                style={{
                  fontSize: !mobile? "100px": "36px",
                  color: "white",
                  zIndex: "1"
                }}
              />
            </Fab>
          </div>
          <Pagination
            updatePosition={updatePosition}
            centerSlideDataIndex={centerSlideDataIndex}
          />
        </div>
      </Container>
    </>
  );
};

export default CarSection;