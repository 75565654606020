import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import './Spotlight.css';
import { useHistory } from "react-router";
import spotlight from "../Blogs/data";
import useWindowDimensions from '../../hooks/useWindowDimensions';
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

const Spotlight = () => {
  const history = useHistory();
  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }

  const openBlog = (id) => {
    history.push(`/blog/${id}`);
  }

  // function iOS() {
  //   return [
  //     'iPad Simulator',
  //     'iPhone Simulator',
  //     'iPod Simulator',
  //     'iPad',
  //     'iPhone',
  //     'iPod'
  //   ].includes(navigator.platform)
  //   || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  // }
  // const [isIOS, setIsIOS] = useState(iOS());

  const settings = { 
    dots: false,
    className: 'sample'
  }
  
  return (
    <Container 
      className='p-0'
      fluid={true} 
      style={{
        marginTop: "5vh",
        marginBottom: "10vh",
      }}
    >
      <div className='d-flex justify-content-center align-items-center'>
        <hr className='spotlight-line' size="8"/>
      </div>   
      <img className='spotlight-title'
        alt='xilver_spotlight'
        src={require("../../../assets/images/Spotlight/Spotlight.png")}
      />
      {!mobile && (
        <Row className='row-spotlight'>
          {spotlight.slice(0,3).map((data, idx) => {
            return(
              <Col md="3" key={idx}>
                <img 
                  onClick={() => openBlog(data.id)}
                  className="spotlight-img"
                  style={{marginBottom: mobile? "5vh": "0vh"}}
                  src={data.image}
                />
                <p 
                  onClick={() => openBlog(data.id)} 
                  style={{cursor: "pointer"}}
                  className='collab-title'>{data['title-landing']}
                </p>
              </Col>
            )}
          )};
        </Row>
      )}
      {mobile && (
        <div style={{width: "100vw"}}>
          <Slider {...settings}>
          {spotlight.map((data, idx) => {
            return(
            <div key={idx}>
              <div className='d-flex justify-content-center align-items-center'>
                <img 
                  onClick={() => openBlog(data.id)}
                  className="spotlight-img"
                  style={{marginBottom: mobile? "5vh": "0vh"}}
                  src={data.image}
                />
              </div>
              <p 
                onClick={() => openBlog(data.id)}
                className='collab-title'>{data['title-landing']}
              </p> 
            </div>
          )})}
          </Slider>
        </div>
      )}
      {/* {mobile && isIOS && ( */}
        {/* <div style={{width: "100vw"}}>
          <Slider {...settings}>
          {spotlight.map((data, idx) => {
            return(
            <div key={idx}>
              <div className='d-flex justify-content-center align-items-center'>
                <a href={`blog/${data.id}`}>
                  <img 
                    className="spotlight-img"
                    style={{marginBottom: mobile? "5vh": "0vh"}}
                    src={data.image}
                  />
                </a>
              </div>
              <p 
                href={`blog/${data.id}`}
                className='collab-title'>{data['title-landing']}
              </p> 
            </div>
          )})}
          </Slider>
        </div> */}
      {/* )} */}
      <p className="read-all" onClick={() => history.push('/blog/0')}>Read All</p>
      <div className='d-flex justify-content-center align-items-center'>
        <hr className='spotlight-line' size="8"/>
      </div>
    </Container>
  );
};

export default Spotlight;