import React from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import "./styles.css";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {db} from "../../../firebase.js";

class Question extends React.Component {
  state = {
    count: 0,
    modalOpen: false,
    modalClose: true,
    userEmail: "",
    userSubscribed: false,
    pick: false,
    allClear: true,
    correct: false,
    isBookmarked: false,
    bookmarkedQuestions: []
  };

  correctOrIncorrect = pick => {
    if (pick === this.state.pick) {
      return this.state.correct ? "correct" : "incorrect";
    } else if (
      pick === !this.state.correct &&
      this.props.currentQuestion.correctAnswer
    ) {
      return "correct";
    } else {
      return "clear";
    }
  };

  handleSelect = pick => {
    this.setState({allClear: false})
    if (pick === this.props.currentQuestion.correctAnswer) {
      this.setState({ pick, correct: true });
    } else {
      this.setState({ pick });
    }
  };

  openModal = () => {
    this.setState({modalOpen: true, modalClose: false})
  };

  closeModal = () => {
    this.setState({modalOpen: false, modalClose: true})
  }

  moveToNextQuestion = () => {
    this.setState({ count: this.state.count+1, pick: false, correct: false, allClear: true });
    if(this.state.count === 1) this.openModal();
    this.props.nextQuestionHandler(this.state.correct);
  };

  addSubscriber = (e) => {
    e.preventDefault();
    db.ref("collab-game-users").push({
      email: this.state.userEmail,
    }).catch(alert);
    sessionStorage.setItem('collab-game-user', this.state.userEmail);
    this.closeModal();
    this.setState({userSubscribed: true, userEmail: ""})
  };

  render() {
    const { shuffledAnswerChoices } = this.props;

    return (
      <div className={this.props.animation ? this.props.animation : null}>
        <div>
          {shuffledAnswerChoices.map((pick, idx) => {
            return (
              <div key={idx}>
                {this.state.allClear && 
                  <Button
                    className={
                      this.state.pick ? this.correctOrIncorrect(pick) : null
                    }
                    style={{color:"white", width: "90%", minHeight:"50px", maxHeight: "200%"}}
                    onClick={() => this.handleSelect(pick)}
                  >
                    <span>{pick}</span>
                  </Button>
                }
                {!this.state.allClear && 
                  <Button
                    className={
                      pick === this.props.currentQuestion.correctAnswer ? "correct" : "incorrect"
                    }
                    style={{color:"white", width: "90%", minHeight:"50px", maxHeight: "200%"}}
                  >
                    <span>{pick}</span>
                  </Button>
                }
                <br/><br/>
              </div>
            );
          })}
          {this.state.modalOpen && !sessionStorage.getItem('collab-game-user') && (
            <div className="sneaker-collab-modal">
              <Modal
                isOpen={true}
                size="lg"
                ariaHideApp={false}
                className='modal-collab'
                centered
              >
                <Col className="modal-header">
                  <Col md={12}>
                    <p
                      className="modal-collab-title font-face-nexa"
                    > Sneaker Collab Game
                    </p>
                  </Col>
                  <Col md={0}>
                    <button
                      type="button"
                      aria-hidden="true"
                      className="close"
                      onClick={this.closeModal}
                    >
                      <CloseOutlinedIcon/>
                    </button>
                  </Col>
                </Col>
                <ModalBody className="modal-collab-body">
                  <Row className="d-flex justify-content-center align-items-center">
                    <Col md={6} className="d-flex justify-content-center">
                      <img 
                        src={require("../../../assets/images/MystryBox.png")}
                       className="img-fluid"
                       style={{width: "75%"}}
                      />
                    </Col>
                    <Col md={6}className="modal-collab-info">
                      <p style={{textAlign: "center"}} className='font-face-nexa'>
                        <span className="heading-one">Guess all 30 Sneakers correctly</span><br/>
                        <span className="heading-two">
                          Stand a Chance to Win a Free Luxury Sneaker Mystry Box
                        </span>
                      </p>
                      <form className="subs-form mb-4" id="form-subs">
                        <input 
                          type="text" 
                          placeholder="Email Address*" 
                          className="subs-field"
                          onChange={(e) => this.setState({userEmail: e.target.value})}
                          required
                        />
                        <input 
                          type="submit" 
                          value="Subscribe" 
                          className='subs-button font-face-nexa' 
                          id="form_submit"
                          onClick={this.addSubscriber}
                        >
                        </input>
                      </form>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </div>
          )}
        </div>
        {this.state.pick && (
          <div className="d-flex justify-content-center align-items-center font-face-nexa">
            <button className="btn btn-xilver-collab" 
              onClick={this.moveToNextQuestion}
            >Continue
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default Question;
