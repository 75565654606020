import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { useHistory } from 'react-router-dom';
import './NavbarMobile.css';

function NavbarDesktop() {
  const expand = false;
  const history = useHistory();

  return (
    <>
      <Navbar key={expand} bg="light">
        <Container fluid>
          <div className="row"  onClick={() => history.push("/")}>
            <div className="col d-flex justify-content-center align-items-center">
              <img 
                className="img-fluid d-flex align-items-center p-1" alt="xilver_logo" width="2%"
                src={require("../../../assets/images/xilver-logo-black.png")}
              />
              <Navbar.Brand 
                className='mt-2 p-2 font-face-nexa'
                style={{
                  fontWeight: 900,
                  cursor: "pointer"
                }}
              >
                XILVER LABS
              </Navbar.Brand>
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarDesktop;