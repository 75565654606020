import React, { useState } from 'react';
import { Container } from 'reactstrap';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import blogs from "../Blogs/data";
import "./Blog.css";
import Parser from "html-react-parser";
import Footer from '../Footer/Footer';
import { useHistory } from 'react-router-dom';
import Comment from './Comment';
import NavbarMobile from '../Navbar/NavbarMobile';
import NavbarDesktop from '../Navbar/Navbar';

const Blog = ({match}) => {
  const { width, height } = useWindowDimensions();
  var mobile;
  if (width < 768) {
    mobile = true;
  } else if (height < 576 || width < 992) {
    mobile = false;
  } else {
    mobile = false;
  }

  const history = useHistory();

  const handlePrevious = () => {
    if(match.params.id == 0)
      history.push('/blog/4')
    else {
      const id = Number(match.params.id) - 1;
      history.push(`/blog/${id}`)
    }
  }

  const handleNext = () => {
    if(match.params.id == 4)
      history.push('/blog/0')
    else {
      const id = Number(match.params.id) + 1;
      history.push(`/blog/${id}`)
    }
  }

  return (
    <>
      {mobile? <NavbarMobile/>: <NavbarDesktop/>}
      <Container style={{marginBottom: mobile? "5vh":"10vh"}}>
        <h1 
          style={{
            marginTop: "10vh",
            marginBottom: "10vh",
            color: "white",
            paddingRight: mobile? "15px": "0px",
            paddingLeft: mobile? "15px": "0px",
            fontSize: mobile? "25px": "45px",
            fontWeight: "700",
            wordSpacing: "5px"
          }}
          className='font-face-nexa'
        >
          {blogs[match.params.id].title}
        </h1>
        {blogs[match.params.id].blogImage1 && (
          <img
            src={blogs[match.params.id].blogImage1}
            className='img-fluid blog-img'
            alt='xilver_blog'
            style={{
              width: mobile? "85%": "60%",
              marginBottom: mobile? "10vh": "15vh"
            }}
          />
        )}
        {blogs[match.params.id].para1 && (
          <div className='blog-data'>
            <span className='blog-para'>{Parser(blogs[match.params.id].para1)}</span>
          </div>
        )}
        <div style={{marginBottom: mobile? "85px": "150px"}}>
          {blogs[match.params.id].blogImage2 && (<img
            src={blogs[match.params.id].blogImage2}
            className='img-fluid blog-img'
            alt='xilver_blog'
            style={{
              width: mobile? "75%": ""
            }}
          />)}
          <div className='blog-data'>
            <p className='blog-para-2'>{Parser(blogs[match.params.id].para2)}</p>
          </div>
          {blogs[match.params.id].blogImage3 && (<img
            src={blogs[match.params.id].blogImage3}
            className='img-fluid blog-img'
            alt='xilver_blog'
            style={{
              width: mobile? "75%": ""
            }}
          />)}
          {blogs[match.params.id].para3 && (
            <div className='blog-data'>
              <p className='blog-para-2'>{Parser(blogs[match.params.id].para3)}</p>
            </div>
          )}
          {blogs[match.params.id].blogImage4 && (<img
            src={blogs[match.params.id].blogImage4}
            className='img-fluid blog-img'
            alt='xilver_blog'
            style={{
              width: mobile? "75%": ""
            }}
          />)}
          {blogs[match.params.id].para4 && (
            <div className='blog-data'>
              <p className='blog-para-2'>{Parser(blogs[match.params.id].para4)}</p>
            </div>
          )}
          {blogs[match.params.id].blogImage5 && (<img
            src={blogs[match.params.id].blogImage5}
            className='img-fluid blog-img'
            alt='xilver_blog'
            style={{
              width: mobile? "75%": ""
            }}
          />)}
          {blogs[match.params.id].para5 && (
            <div className='blog-data'>
              <p className='blog-para-2'>{Parser(blogs[match.params.id].para5)}</p>
            </div>
          )}
          {blogs[match.params.id].blogImage6 && (<img
            src={blogs[match.params.id].blogImage6}
            className='img-fluid blog-img'
            alt='xilver_blog'
            style={{
              width: mobile? "75%": ""
            }}
          />)}
          {blogs[match.params.id].para6 && (
            <div className='blog-data'>
              <p className='blog-para-2'>{Parser(blogs[match.params.id].para6)}</p>
            </div>
          )}
          {blogs[match.params.id].blogImage7 && (<img
            src={blogs[match.params.id].blogImage7}
            className='img-fluid blog-img'
            alt='xilver_blog'
            style={{
              width: mobile? "75%": ""
            }}
          />)}
          {blogs[match.params.id].para7 && (
            <div className='blog-data'>
              <p className='blog-para-2'>{Parser(blogs[match.params.id].para7)}</p>
            </div>
          )}
          {blogs[match.params.id].blogImage8 && (<img
            src={blogs[match.params.id].blogImage8}
            className='img-fluid blog-img'
            alt='xilver_blog'
            style={{
              width: mobile? "75%": ""
            }}
          />)}
          {blogs[match.params.id].para8 && (
            <div className='blog-data'>
              <p className='blog-para-2'>{Parser(blogs[match.params.id].para8)}</p>
            </div>
          )}
        </div>
        <Comment id={match.params.id}/>
        {mobile && (
          <div className='blog-data row' style={{cursor:"pointer"}}>
            <div className='col-md-6 col-4'>
              <img 
                src={require("../../../assets/images/blogs/previous.png")} 
                onClick={() => handlePrevious()}
                style={{width: "115px", height: "25px"}}
              />
            </div>
            <div className='col-2'></div>
            <div className='col-md-6 col-4 text-right'>
              <img 
                src={require("../../../assets/images/blogs/next.png")} 
                onClick={() => handleNext()}
                style={{width: "80px", height: "25px"}}

              />
            </div>
          </div>
        )}
        {!mobile && (
          <div className='blog-data row' style={{cursor:"pointer"}}>
            <div className='col-md-6 col-4'>
              <img 
                src={require("../../../assets/images/blogs/previous.png")} 
                onClick={() => handlePrevious()}
                className='img-fluid zoomOnHover'
              />
            </div>
            <div className='col-md-6 col-4 text-right'>
              <img 
                src={require("../../../assets/images/blogs/next.png")} 
                onClick={() => handleNext()}
                className='img-fluid zoomOnHover'
              />
            </div>
          </div>
        )}
      </Container>
      <Footer/>
    </>
  );
};

export default Blog;